angular.module('app').factory('menuFactory', function () {
  return {
    buildMenu: function (role) {
      var menu = '';
      switch (role) {
        default:
        case 'super admin': // Super Admin menu items here
          menu = [
            {
              title: 'Analytics',
              icon: 'fa-dashboard',
              url: 'dashboard.dashboard',
            },
            {
              title: 'Group Management',
              icon: 'fa-users',
              url: '#',
              submenu: [
                {
                  title: 'Create a group',
                  url: 'dashboard.create-group',
                },
                {
                  title: 'Manage group',
                  url: 'dashboard.manage-group',
                },
              ],
            },
            {
              title: 'User Management',
              icon: 'fa-user-o',
              url: '#',
              submenu: [
                {
                  title: 'Create a user',
                  url: 'dashboard.create-user',
                },
                {
                  title: 'Manage user',
                  url: 'dashboard.manage-user',
                },
              ],
            },
            {
              title: 'Group Results',
              icon: 'fa-pie-chart',
              class: 'group-results',
              url: '#',
              submenu: [],
            },
          ];
          break;

        case 'facilitator': // Facilitator menu items here
          menu = [
            {
              title: 'Dashboard',
              icon: 'fa-dashboard',
              url: 'dashboard.dashboard',
            },
            {
              title: 'Group Management',
              icon: 'fa-users',
              url: '#',
              submenu: [
                {
                  title: 'Create a group',
                  url: 'dashboard.create-group',
                },
                {
                  title: 'Manage group',
                  url: 'dashboard.manage-group',
                },
              ],
            },
            /* {
								title: 'User Management',
								icon: 'fa-user',
								url: '#',
								submenu: [{
									title: 'Create User',
									url: 'dashboard.create-user'

								}, {
									title: 'Assign User',
									url: 'dashboard.assign-user'
								}, {
									title: 'Edit User',
									url: 'dashboard.edit-user'
								}]
							}, */
            {
              title: 'Group Results',
              icon: 'fa-pie-chart',
              class: 'group-results',
              url: '#',
              submenu: [],
            },
          ];
          break;

        case 'user':
          menu = [
            {
              title: 'Dashboard',
              icon: 'fa-dashboard',
              url: 'dashboard.dashboard',
            },
            {
              title: 'Group Results',
              icon: 'fa-pie-chart',
              class: 'group-results',
              url: '#',
              submenu: [],
            },
          ];
          break;
      }
      return menu;
    },
  };
});
