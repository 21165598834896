angular
  .module('app')
  .component('dashboardHeader', {
    templateUrl: 'app/header.html',
    controllerAs: 'vm',

    controller: function ($state, $scope, $localStorage) {
      // Set this to self to avoid conflicts
      const self = this;

      // Receive header title from sibling section
      $scope.$on('headerTitle', function (event, args) {
        self.title = args.title;
        self.icon = args.icon;
      });

      // Set the environment
      self.env = env;

      self.$onInit = function () {
        // if (angular.isDefined($localStorage.user)) {
        // Set the user name
        self.displayName = $localStorage.user.name + ' ' + $localStorage.user.surname;
        // Set the users profile picture
        self.profilePicture = $localStorage.user.path + '/' + $localStorage.user.profile_picture;
        // }
      };
    }
  });
