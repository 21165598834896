angular.module('app').component('assignFacilitator', {
  templateUrl: 'app/dashboard/facilitator/facilitator-assign.html',
  controllerAs: 'vm',
  bindings: {
    facilitators: '<',
    groups: '<',
  },

  controller: function ($rootScope, facilitatorFactory, sweetAlert, titleService) {
    // Set this to self to avoid conflicts
    const self = this;

    // Set the basic controller data to be passed to title service
    titleService.data = {
      title: 'Assign Facilitator',
      subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc justo augue, placerat quis nisi sit amet, feugiat tristique mi.',
    };

    // Broadcast section title to the header
    $rootScope.$broadcast('headerTitle', {
      title: 'Facilitator Management',
      icon: 'fa-user',
    });

    // Run when component is ready
    self.$onInit = function () {
      // Set the initial button state
      self.button = 'Assign Facilitator';
      // Define group options for multi select
      self.groupOptions = [];

      // Sort group names alphabetically
      self.groups = self.groups.sort(function (a, b) {
        return a.name > b.name ? 1 : -1;
      });
      // Build group options
      angular.forEach(self.groups, function (value, key) {
        self.groupOptions.push({
          id: value.id,
          group: value.name,
        });
      });

      self.labels = {
        itemsSelected: 'text you want',
        search: 'Search...',
      };
    };

    // Handle the facilitator assign
    self.assignFacilitatorHandler = function () {
      // Change button text
      self.button = 'Assigning Facilitator...';

      // Define selected group id array
      self.selectedGroupID = [];
      // Build group array to send to enpoint
      angular.forEach(self.selection, function (value, key) {
        self.selectedGroupID.push(value.id);
      });

      // Set the input object
      const input = {
        group_id: self.selectedGroupID,
        facilitator_id: self.facilitator.id,
      };

      // Call the facilitatorFactory create method
      facilitatorFactory.assign(input).then(
        function (result) {
          // Show sweet alert with callback
          sweetAlert
            .swal({
              title: 'Great!',
              text: 'The facilitator was successfully assigned',
              type: 'success',
            })
            .then(function () {
              // Change button text back
              self.button = 'Assign Facilitator';
              // Reset the fields
              self.facilitator = null;
              self.group = null;
            });
        },
        function (error) {
          console.log(error);
          // Show sweet alert with callback
          sweetAlert
            .swal({
              title: 'Oops!',
              text: error.data.message,
              type: 'error',
            })
            .then(function () {
              // Change login button text back
              self.button = 'Assign Facilitator';
            });
        }
      );
    };
  },
});
