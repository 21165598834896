angular.module('app').component('password', {
  templateUrl: 'app/auth/password.html',
  controllerAs: 'vm',

  controller: function ($state, $stateParams, $localStorage, $timeout, authFactory, sweetAlert) {
    // Set this to self to avoid conflicts
    const self = this;

    // Run when component is ready
    self.$onInit = function () {
      // Set initial button text
      self.button = 'Submit Password';

      // Set the token
      self.token = $stateParams.token;
    };

    // Handle user sign in
    self.passwordHandler = function () {
      // Change button text
      self.button = 'Submitting Password...';

      // Check passwords match
      if (self.password === self.confirmPassword) {
        // Set the input object
        const input = {
          password: self.password,
          password_confirmation: self.confirmPassword,
          token: self.token,
        };

        authFactory.resetPassword(input).then(
          function (result) {
            $localStorage.user = result.user;
            $localStorage.role = result.user.role_name;
            $localStorage.token = result.user.token;
            // Go to dashboard
            $timeout(function () {
              $state.go('dashboard.dashboard');
            });
          },
          function (error) {
            console.log(error);
            // Show sweet alert message
            sweetAlert.swal(
              {
                title: 'Oops!',
                text: error.data,
                type: 'error',
              },
              function () {
                self.button = 'Submit Password';
              }
            );
          }
        );
      } else {
        // Show sweet alert message
        sweetAlert.swal(
          {
            title: 'Oops!',
            text: 'The passwords do not match',
            type: 'error',
          },
          function () {
            self.button = 'Submit Password';
          }
        );
      }
    };
  },
});
