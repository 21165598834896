angular.module('app').component('createGroup', {
  templateUrl: 'app/dashboard/group/group-create.html',
  controllerAs: 'vm',
  bindings: {
    groups: '<',
  },

  controller: function ($rootScope, $state, $localStorage, $timeout, groupFactory, sweetAlert, titleService) {
    // Set this to self to avoid conflicts
    const self = this;

    // Set the basic controller data to be passed to title service
    titleService.data = {
      title: 'Create Group',
      subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc justo augue, placerat quis nisi sit amet, feugiat tristique mi.',
    };

    // Broadcast section title to the header
    $rootScope.$broadcast('headerTitle', {
      title: 'Group Management',
      icon: 'fa-users',
    });

    // Run when component is ready
    self.$onInit = function () {
      // Set the initial button text
      self.button = 'Create Group';
    };

    // Format date for api
    self.formatDate = function (date) {
      const month = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
      const day = date.getDate();
      const monthIndex = date.getMonth();
      const year = date.getFullYear();

      return day + '-' + month[monthIndex] + '-' + year;
    };

    // Handle group create handler
    self.createGroupHandler = function () {
      // Change button text
      self.button = 'Creating Group...';

      // Set the input object
      const input = {
        name: self.name,
        end_date: self.emailReminder === 'true' ? self.formatDate(new Date(self.endDate)) : null,
        reminder_date: self.emailReminder === 'true' ? self.formatDate(new Date(self.reminderDate)) : null,
        reminder_language: 'en',
        email_text: self.emailReminder === 'true' ? self.emailText : null,
      };

      // Call the groupFactory create method
      groupFactory.create(input).then(
        function (response) {
          // Change login button text back
          self.button = 'Create Group';
          // Reset the input value
          self.name = null;
          // Show sweet alert with callback
          sweetAlert
            .swal({
              title: 'Great!',
              text: 'Your group has been successfully created',
              type: 'success',
            },
            function (result) {
                // Fetch all the groups
              groupFactory.fetch().then(
                  function (result) {
                    // Reset the local storage group
                    $localStorage.group = result.group;
                    // Broadcast to menu to trigger menu rebuild
                    $rootScope.$broadcast('rebuildGroups');
                    // Redirect to user edit and send user new user object
                    $timeout(function () {
                      $state.go('dashboard.manage-group', {
                        group: response.group.slice(-1)[0],
                      });
                    });
                  },
                  function (error) {
                    console.log(error);
                  }
                );
            },
              function (error) {
                console.log(error);
              }
            );
        },
        function (error) {
          console.log(error);
          // Show sweet alert with callback
          sweetAlert.swal({
            title: 'Oops!',
            text: error.data.message.name,
            type: 'error',
          });
        }
      );
    };
  },
});
