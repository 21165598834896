angular.module('app').factory('authFactory', function ($state, $http, $localStorage, $timeout, __env) {
  return {
    login: function (input) {
      if (__env.enableDebug) {
        console.log('loginService.login ran **********');
      }
      return $http({
        method: 'POST',
        url: __env.apiUrl + 'login',
        data: input,
      })
        .then(function (response) {
          return response.data;
        })
        .then(function (error) {
          return error;
        });
    },
    /**
		 * Request reset password token
		 * @param  {object} $input Users email address
		 * @return {json} Returns a success or error message
		 */
    reset: function (input) {
      if (__env.enableDebug) {
        console.log('loginService.reset ran **********');
      }
      return $http({
        method: 'POST',
        url: __env.apiUrl + 'password/email',
        data: input,
      })
        .then(function (response) {
          return response.data;
        })
        .then(function (error) {
          return error;
        });
    },
    /**
		 * Logout the user by resetting the users local storage variables
		 */
    logout: function () {
      if (__env.enableDebug) {
        console.log('loginService.logout ran **********');
      }
      // Reset the user local storage variables
      $localStorage.$reset();

      // Go to login state
      $timeout(function () {
        $state.go('login');
      });
    },
    /**
		 * End Logout
		 */

    /**
		 * ResetPassword resets the users password
		 */
    resetPassword: function (input) {
      if (__env.enableDebug) {
        console.log('loginService.password ran **********');
      }
      return $http({
        method: 'POST',
        url: __env.apiUrl + '/facilitator/password',
        data: input,
      })
        .then(function (response) {
          return response.data;
        })
        .then(function (error) {
          return error;
        });
    },
    /**
		 * End Reset Password
		 */
  };
});
