angular
  .module('app')
  .component('groupDashboard', {
    templateUrl: 'app/dashboard/game/group-dashboard.html',
    controllerAs: 'vm',

    controller: function ($rootScope, $scope, $localStorage, titleService, groupFactory) {
      // Set this to self to avoid conflicts
      const self = this;
      // Set the scopes environement variable
      self.env = env;

      // Set the basic controller data to be passed to title service
      titleService.data = {
        title: 'Group Dashboard',
        subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc justo augue, placerat quis nisi sit amet, feugiat tristique mi.'
      };

      // Broadcast section title to the header
      $rootScope.$broadcast('headerTitle', {
        title: 'Group Results',
        icon: 'fa-pie-chart'
      });

      // Run when component is ready
      self.$onInit = function () {
        self.groups = $localStorage.group;

        self.rebuildGroupsButton = 'Refresh Groups';
      };

      // Rebuild Groups
      $scope.$on('rebuildGroups', function (event, args) {
        self.groups = $localStorage.group;
      });

      self.handleRebuildGroups = function () {
        self.rebuildGroupsButton = 'refreshing Groups...';
        groupFactory.fetch()
          .then(function (result) {
            // Set the group local storage to handle page refreshing
            $localStorage.group = result.group;
            // Broadcast to rebuild the group menu
            $rootScope.$broadcast('rebuildGroups');
            // Update button
            self.rebuildGroupsButton = 'Refresh Groups';
          }, function (error) {
            console.log(error);
          });
      };

      // Set the game one options for the percentge display
      self.gameOneOptions = {
        size: 65,
        unit: '%',
        readOnly: true,
        trackWidth: 5,
        barWidth: 8,
        trackColor: '#ccc',
        barColor: '#ef4d84'
      };
      // Set the game two options for the percentge display
      self.gameTwoOptions = {
        size: 65,
        unit: '%',
        readOnly: true,
        trackWidth: 5,
        barWidth: 8,
        trackColor: '#ccc',
        barColor: '#99ca43'
      };
      // Set the game three options for the percentge display
      self.gameThreeOptions = {
        size: 65,
        unit: '%',
        readOnly: true,
        trackWidth: 5,
        barWidth: 8,
        trackColor: '#ccc',
        barColor: '#f58220'
      };
    }
  });
