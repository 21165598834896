// Set the environment variables
const env = {};

// Import variables if present (from env.js)
if (window) {
  Object.assign(env, window.__env);
}

// Define AngularJS application
angular
  .module('app', [
    'ui.router',
    'angular-loading-bar',
    'ngAnimate',
    'ngStorage',
    'ngSanitize',
    'ngToast',
    'btorfs.multiselect',
    '720kb.tooltips',
    '720kb.datepicker',
    '19degrees.ngSweetAlert2',
    'pathgather.popeye',
    'ngFileUpload',
    'ui.knob',
    'draganddrop',
    'ngFileSaver'
  ])
  .constant('__env', env) // Register environment in AngularJS as constant
  .run(function ($rootScope, $state, $transitions, $window, $localStorage, $timeout, groupFactory, sweetAlert) {
    // On successful state load - Object takes 'from' an 'to' states (optional)
    $transitions.onSuccess({}, function () {
      // Check if current state is restricted or not (defined in routes.js)
      var restricted = $state.current.restricted;

      var thisState = window.location.pathname;

      // Go to login page if user object is not present (user has not been authenticated)
      if (restricted && !$localStorage.user) {
        $timeout(function () {
          $state.go('login');
        });
      } else if ($localStorage.user) {
        return groupFactory.fetch().then(
          function (result) {
            return 'ok';
          },
          function (error) {
            if (error.data.error === 'Unauthenticated.') {
              sweetAlert
                .swal({
                  title: 'Oops!',
                  text: 'It appears as though another user has logged in with this account. Please, log back in again to continue.',
                  type: 'error',
                })
                .then(function () {
                  $localStorage.user = null;
                  $localStorage.token = null;
                  $state.go('login');
                });
            }
            console.log(error);
          }
        );
      }

      // Toggle active class on hamburger icon
      if ($('#wrapper').hasClass('is-active')) {
        $('#sidebar-wrapper, #wrapper').toggleClass('is-active');
        $('.hamburger, .hamburger--spin').toggleClass('is-active');
      }

      // Scrollt to top of window on state change
      $window.scrollTo(0, 0);
    });
    $transitions.onStart({}, function ($transition) {
      // Do some stuff here if you want
    });
  });
