angular
  .module('app')
  .component('assignUser', {
    templateUrl: 'app/dashboard/user/user-assign.html',
    controllerAs: 'vm',
    bindings: {
      users: '<',
      groups: '<'
    },

    controller: function ($rootScope, userFactory, sweetAlert, titleService) {
      // Set this to self to avoid conflicts
      const self = this;
      // Set the basic controller data to be passed to title service
      titleService.data = {
        title: 'Assign User',
        subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc justo augue, placerat quis nisi sit amet, feugiat tristique mi.'
      };

      // Broadcast section title to the header
      $rootScope.$broadcast('headerTitle', {
        title: 'User Management',
        icon: 'fa-user-o'
      });

      // Run when component is ready
      self.$onInit = function () {
        // Set the initial button state
        self.button = 'Assign User';
        // Define group options for multi select
        self.groupOptions = [];

        // Sort group names alphabetically
        self.groups = self.groups.sort(function (a, b) {
          return (a.name > b.name) ? 1 : -1;
        });
        // Build group options
        angular.forEach(self.groups, function (value, key) {
          self.groupOptions.push({
            id: value.id,
            group: value.name,
          });
        });
      };

      // Handle the user assign
      self.assignUserHandler = function () {
        // Change button text
        self.button = 'Assigning User...';

        // Define selected group id array
        self.selectedGroupID = [];
        // Build group array to send to enpoint
        angular.forEach(self.selection, function (value, key) {
          self.selectedGroupID.push(value.id);
        });

        // Set the input object
        const input = {
          group_id: self.selectedGroupID,
          email: self.email
        };

        // Call the userFactory create method
        userFactory.assign(input)
          .then(function (result) {
            // Change button text back
            self.button = 'Assign User';

            // Show sweet alert with callback
            sweetAlert.swal({
              title: 'Great!',
              text: 'The user was successfully assigned',
              type: 'success'
            });
          }, function (error) {
            console.log(error);
            // Show sweet alert with callback
            sweetAlert.swal({
              title: 'Oops!',
              text: error.data.message,
              type: 'error'
            });
          });
      };
    }

  });
