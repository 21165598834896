angular.module('app').component('createFacilitator', {
  templateUrl: 'app/dashboard/facilitator/facilitator-create.html',
  controllerAs: 'vm',
  bindings: {
    countries: '<',
  },

  controller: function ($state, $rootScope, $timeout, facilitatorFactory, sweetAlert, titleService) {
    // Set this to self to avoid conflicts
    const self = this;
    // Set the basic controller data to be passed to title service
    titleService.data = {
      title: 'Create Facilitator',
      subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc justo augue, placerat quis nisi sit amet, feugiat tristique mi.',
    };

    // Broadcast section title to the header
    $rootScope.$broadcast('headerTitle', {
      title: 'Facilitator Management',
      icon: 'fa-user',
    });

    // Run when component is ready
    self.$onInit = function () {
      // Set the initial button state
      self.button = 'Create Facilitator';
    };
    self.clickHere = function () {
      $('#3').click();
      $timeout(function () {
        $state.go('dashboard.edit-user');
      });
    };
    self.createFacilitatorHandler = function () {
      // Change button text
      self.button = 'Creating Facilitator...';
      // Set the input object
      const input = {
        name: self.name,
        surname: self.surname,
        email: self.email,
        age: self.age,
        gender: self.gender,
        country: self.country,
      };
      // Call the facilitatorFactory create method
      facilitatorFactory.create(input).then(
        function (result) {
          // Show sweet alert with callback
          sweetAlert.swal(
            {
              title: 'Great!',
              text: 'Your facilitator has been successfully created',
              type: 'success',
            },
            function () {
              // Change button text back
              self.button = 'Create Facilitator';
              // Reset the form
              self.name = null;
              self.surname = null;
              self.email = null;
              self.age = null;
              self.gender = null;
              self.country = null;
            }
          );
        },
        function (error) {
          // Show sweet alert with callback
          sweetAlert.swal(
            {
              title: 'Oops!',
              text: error.data.message.email,
              type: 'error',
            },
            function () {
              // Change the button text back
              self.button = 'Create Facilitator';
            }
          );
        }
      );
    };
  },
});
