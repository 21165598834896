angular
  .module('app')
// Convert string to number directive
  .directive('number', function () {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, element, attr, ngModel) {
        function toModel(value) {
          return String(value); // convert to string
        }

        function toView(value) {
          return parseInt(value, 10); // convert to number
        }
        ngModel.$formatters.push(toView);
        ngModel.$parsers.push(toModel);
      },
    };
  })
// Scroll to element on page directive
  .directive('scrollTo', function () {
    return {
      restrict: 'A',
      scope: {
        scrollTo: '@',
      },
      link: function (scope, $elm, attr) {
        $elm.on('click', function () {
          // Checks if archetype has results
          if (scope.$parent.data.percentage !== 0) {
            $('html,body').animate(
              {
                scrollTop: $(scope.scrollTo).offset().top - 60, // Subtract the height of the header
              },
              'slow'
            );
          }
        });
      },
    };
  })
// Assign words to quadrant directive
  .directive('toolbar', function ($rootScope, gameFactory, sweetAlert, $state) {
    return {
      restrict: 'A',
      link: function (scope, element, attrs, vm) {
        $(element).toolbar({
          event: 'click',
          hideOnClick: true,
          content: '#toolbar-options',
          adjustment: 35,
        });
        // Capture the click event
        $(element).on('toolbarItemClick', function (event, el) {
          const word = this.innerText; // Get the word that is being assigned to quadrant
          const quadrant = $(el).data('quad-id'); // Get the quadrant id we want to assign word to
          const input = {
            word: word,
            quadrant: quadrant,
            group_id: scope.vm.group.id,
          };

          // Assign the word to selected quadrant
          gameFactory.assignWord(input).then(
            function (result) {
              // Show sweet alert with callback
              sweetAlert.swal(
                {
                  title: 'Great!',
                  text: 'Word successfully added to quadrant. The dashboard will update shortly to reflect these changes.',
                  type: 'success',
                },
                function () {
                  // Refresh the group results
                  scope.vm.refreshGroupResults();
                }
              );
              // Trigger body click to close the toolbar
              $('body').trigger('click');
            },
            function (error) {
              console.log(error);
            }
          );
        });
      },
    };
  })
// Consolidate words toggle directive
  .directive('consolidate', function ($timeout) {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        const el = $(element);
        el.on('click', function () {
          let word = el.text();
          let i = scope.arrWords.indexOf(word);
          // toggle word in array
          $timeout(function () {
            if (i === -1) {
              scope.arrWords.push(word);
            } else {
              scope.arrWords.splice(i, 1);
            }
          });
          // Toggle active class
          el.toggleClass('active');
        });
      },
    };
  })
// Toggle Hamburger Menu
  .directive('hamburger', function ($timeout) {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        const el = $(element);
        el.on('click', function () {
          // if(el.hasClass('is-active')){
          // 	$('.submenu').removeClass('show');
          // }
          // Toggle active class on hamburger icon
          el.toggleClass('is-active');
          // Toggle active class on menu and wrapper
          $('#sidebar-wrapper, #wrapper').toggleClass('is-active');
        });
      },
    };
  });
