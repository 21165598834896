angular
  .module('app')
  .factory('facilitatorFactory', function ($http, $localStorage, __env) {
    return {
      /**
			 * Fetch facilitators
			 */
      fetch: function () {
        if (__env.enableDebug) {
          console.log('facilitatorFactory.fetch ran **********');
        }
        return $http({
          method: 'GET',
          url: __env.apiUrl + 'facilitators',
          headers: {
            Authorization: 'Bearer ' + $localStorage.token
          }
        })
          .then(function (response) {
            return response.data;
          }).then(function (error) {
            return error;
          });
      },
      /**
			 * Create a new facilitator
			 */
      create: function (input) {
        if (__env.enableDebug) {
          console.log('facilitatorFactory.create ran **********');
        }
        return $http({
          method: 'POST',
          url: __env.apiUrl + 'facilitator',
          headers: {
            Authorization: 'Bearer ' + $localStorage.token
          },
          data: input
        })
          .then(function (response) {
            return response.data;
          }).then(function (error) {
            return error;
          });
      },
      /* end create */
      /**
			 * Assign facilitator to facilitator
			 */
      assign: function (input) {
        if (__env.enableDebug) {
          console.log('facilitatorFactory.assign ran **********');
        }
        return $http({
          method: 'POST',
          url: __env.apiUrl + 'facilitator/assign',
          headers: {
            Authorization: 'Bearer ' + $localStorage.token
          },
          data: input
        })
          .then(function (response) {
            return response.data;
          }).then(function (error) {
            return error;
          });
      },
      /* end assign */

      /**
			 * Assign facilitator to facilitator
			 */
      remove: function (input) {
        if (__env.enableDebug) {
          console.log('facilitatorFactory.remove ran **********');
        }
        return $http({
          method: 'POST',
          url: __env.apiUrl + 'facilitator/remove',
          headers: {
            Authorization: 'Bearer ' + $localStorage.token
          },
          data: input
        })
          .then(function (response) {
            return response.data;
          }).then(function (error) {
            return error;
          });
      },
      /* end assign */

      /**
			 * Update facilitator to facilitator
			 */
      update: function (input) {
        if (__env.enableDebug) {
          console.log('facilitatorFactory.update ran **********');
        }
        return $http({
          method: 'POST',
          url: __env.apiUrl + 'facilitator/' + input.id,
          headers: {
            Authorization: 'Bearer ' + $localStorage.token
          },
          data: input
        })
          .then(function (response) {
            return response.data;
          }).then(function (error) {
            return error;
          });
      },
      /* end update */

    };
  });
