angular.module('app').factory('gameFactory', function ($http, $localStorage) {
  return {
    /**
		 * Fetch games
		 */
    fetch: function (input) {
      if (__env.enableDebug) {
        console.log('gameFactory.fetch ran **********');
      }

      return $http({
        method: 'GET',
        url: __env.apiUrl + 'dashboard/group/' + input.id,
        data: input,
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
      })
        .then(function (response) {
          return response.data;
        })
        .then(function (error) {
          return error;
        });
    },
    /* end fetch */

    /**
		 * Fetch games by territory
		 */
    fetchTerritory: function (input) {
      if (__env.enableDebug) {
        console.log('gameFactory.fetchTerritory ran **********');
      }

      return $http({
        method: 'GET',
        url: __env.apiUrl + 'dashboard/group/territories/' + input.id,
        data: input,
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
      })
        .then(function (response) {
          return response.data;
        })
        .then(function (error) {
          return error;
        });
    },
    /* end fetch by territory */

    /**
		 * Fetch game one data by archetype
		 */
    fetchGameOneSub: function (input) {
      if (__env.enableDebug) {
        console.log('gameFactory.fetchGameOneSub ran **********');
      }

      return $http({
        method: 'GET',
        url: __env.apiUrl + 'results/group/' + input.id + '/archetype/' + input.answer,
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
      })
        .then(function (response) {
          return response.data;
        })
        .then(function (error) {
          return error;
        });
    },
    /* end game one sub fetch */

    /**
		 * Fetch users game results
		 */
    fetchUsersGameResults: function (input) {
      if (__env.enableDebug) {
        console.log('gameFactory.fetchGameTwoSub ran **********');
      }

      return $http({
        method: 'GET',
        url: __env.apiUrl + 'results/group/' + input.groupID + '/user/' + input.userID,
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
      })
        .then(function (response) {
          return response.data;
        })
        .then(function (error) {
          return error;
        });
    },
    /* Fetch users game results end */

    /**
		 * Assign word to quadrant
		 */
    assignWord: function (input) {
      if (__env.enableDebug) {
        console.log('gameFactory.assignWord ran **********');
      }

      return $http({
        method: 'POST',
        url: __env.apiUrl + 'assign',
        data: input,
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
      })
        .then(function (response) {
          return response.data;
        })
        .then(function (error) {
          return error;
        });
    },
    /* End add word to quadrant */

    /**
		 * Consoldiate words to
		 */
    consolidateWord: function (input) {
      if (__env.enableDebug) {
        console.log('gameFactory.consolidateWord ran **********');
      }

      return $http({
        method: 'POST',
        url: __env.apiUrl + 'consolidate',
        data: input,
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
      })
        .then(function (response) {
          return response;
        })
        .then(function (error) {
          return error;
        });
    },
    /* End consolidate word */

    /**
		 * Include user results
		 */
    includeUserResults: function (input) {
      if (__env.enableDebug) {
        console.log('gameFactory.includeUserResults ran **********');
      }

      return $http({
        method: 'POST',
        url: __env.apiUrl + 'data/add/user/',
        data: input,
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
      })
        .then(function (response) {
          return response;
        })
        .then(function (error) {
          return error;
        });
    },
    /* End include user results */

    /**
		 * Exclude user results
		 */
    excludeUserResults: function (input) {
      if (__env.enableDebug) {
        console.log('gameFactory.excludeUserResults ran **********');
      }

      return $http({
        method: 'POST',
        url: __env.apiUrl + 'data/user/',
        data: input,
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
      })
        .then(function (response) {
          return response;
        })
        .then(function (error) {
          return error;
        });
    },
    /* End exclude user results */

    /**
		 * Fetch copy
		 */
    fetchCopy: function () {
      if (__env.enableDebug) {
        console.log('gameFactory.fetchCopy ran **********');
      }
      return $http({
        method: 'GET',
        url: __env.apiUrl + 'allcopy',
      })
        .then(function (response) {
          return response.data;
        })
        .then(function (error) {
          return error;
        });
    },
    /* End fetch copy */

    /**
		 * Save copy
		 */
    saveCopy: function (input) {
      if (__env.enableDebug) {
        console.log('gameFactory.saveCopy ran **********');
      }
      return $http({
        method: 'POST',
        // url: __env.apiUrl + 'copy',
        url: __env.apiUrl + 'contribution',
        data: input,
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
      })
        .then(function (response) {
          return response.data;
        })
        .then(function (error) {
          return error;
        });
    },
    /* End Save copy */

    /**
		 * Fetch copy
		 */
    fetchArchetypes: function () {
      if (__env.enableDebug) {
        console.log('gameFactory.fetchArchetypes ran **********');
      }
      return $http({
        method: 'GET',
        url: __env.apiUrl + 'allArchetypes',
      })
        .then(function (response) {
          return response.data;
        })
        .then(function (error) {
          return error;
        });
    },
    /* End fetch copy */

    /**
		 * Save Archetype
		 */
    saveArchetype: function (input) {
      if (__env.enableDebug) {
        console.log('gameFactory.saveArchetype ran **********');
      }
      return $http({
        method: 'POST',
        url: __env.apiUrl + 'archetype',
        data: input,
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
      })
        .then(function (response) {
          return response.data;
        })
        .then(function (error) {
          return error;
        });
    },

    /**
		 * Fetch ethos
		 */
    fetchEthoses: function () {
      if (__env.enableDebug) {
        console.log('gameFactory.fetchEthoses ran **********');
      }
      return $http({
        method: 'GET',
        url: __env.apiUrl + 'allEthoses',
      })
        .then(function (response) {
          return response.data;
        })
        .then(function (error) {
          return error;
        });
    },
    /* End fetch ethos */

    /**
		 * Save Ethos
		 */
    saveEthos: function (input) {
      if (__env.enableDebug) {
        console.log('gameFactory.saveEthos ran **********');
      }
      return $http({
        method: 'POST',
        url: __env.apiUrl + 'ethos',
        data: input,
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
      })
        .then(function (response) {
          return response.data;
        })
        .then(function (error) {
          return error;
        });
    },

    /**
		 * Fetch mailers
		 */
    fetchMailers: function () {
      if (__env.enableDebug) {
        console.log('gameFactory.fetchMailers ran **********');
      }
      return $http({
        method: 'GET',
        url: __env.apiUrl + 'allMailers',
      })
        .then(function (response) {
          return response.data;
        })
        .then(function (error) {
          return error;
        });
    },
    /* End fetch mailers */

    /**
		 * Save Mailer
		 */
    saveMailer: function (input) {
      if (__env.enableDebug) {
        console.log('gameFactory.saveMailer ran **********');
      }
      return $http({
        method: 'POST',
        url: __env.apiUrl + 'mailer',
        data: input,
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
      })
        .then(function (response) {
          return response.data;
        })
        .then(function (error) {
          return error;
        });
    },

    /**
		 * Fetch endpoints
		 */
    fetchEndpoints: function () {
      if (__env.enableDebug) {
        console.log('gameFactory.fetchEndpoints ran **********');
      }
      return $http({
        method: 'GET',
        url: __env.apiUrl + 'allEndpoints',
      })
        .then(function (response) {
          return response.data;
        })
        .then(function (error) {
          return error;
        });
    },
    /* End fetch endpoints */

    /**
		 * Save Endpoint
		 */
    saveEndpoint: function (input) {
      if (__env.enableDebug) {
        console.log('gameFactory.saveEndpoint ran **********');
      }
      return $http({
        method: 'POST',
        url: __env.apiUrl + 'endpoint',
        data: input,
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
      })
        .then(function (response) {
          return response.data;
        })
        .then(function (error) {
          return error;
        });
    },

    /**
		 * Fetch GameThree
		 */
    fetchGameThree: function () {
      if (__env.enableDebug) {
        console.log('gameFactory.fetchGameThree ran **********');
      }
      return $http({
        method: 'GET',
        url: __env.apiUrl + 'allGameThree',
      })
        .then(function (response) {
          return response.data;
        })
        .then(function (error) {
          return error;
        });
    },
    /* End fetch copy */

    /**
		 * Save values game
		 */
    saveValue: function (input) {
      if (__env.enableDebug) {
        console.log('gameFactory.saveValue ran **********');
      }
      return $http({
        method: 'POST',
        url: __env.apiUrl + 'values',
        data: input,
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
      })
        .then(function (response) {
          return response.data;
        })
        .then(function (error) {
          return error;
        });
    },

    /**
		 * Fetch gameOne
		 */
    fetchGameOne: function () {
      if (__env.enableDebug) {
        console.log('gameFactory.fetchGameOne ran **********');
      }
      return $http({
        method: 'GET',
        url: __env.apiUrl + 'allGameOne',
      })
        .then(function (response) {
          return response.data;
        })
        .then(function (error) {
          return error;
        });
    },
  };
});
