angular.module('app').component('deleteUser', {
  templateUrl: 'app/dashboard/user/user-delete.html',
  controllerAs: 'vm',
  bindings: {
    users: '<',
  },

  controller: function ($rootScope, $timeout, userFactory, sweetAlert, titleService) {
    // Set this to self to avoid conflicts
    const self = this;
    // Set the basic controller data to be passed to title service
    titleService.data = {
      title: 'Delete User',
      subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc justo augue, placerat quis nisi sit amet, feugiat tristique mi.',
    };

    // Broadcast section title to the header
    $rootScope.$broadcast('headerTitle', {
      title: 'User Management',
      icon: 'fa-user-o',
    });

    // Run when component is ready
    self.$onInit = function () {
      // Set the initial button text
      self.button = 'Delete User';
    };

    // Handle user delete handler
    self.deleteUserHandler = function () {
      // Change button text
      self.button = 'Deleting User...';

      // Set the input object
      const input = {
        id: self.user.id,
      };

      sweetAlert.swal(
        {
          title: 'Are you sure?',
          text: 'You will not be able to recover this user!',
          type: 'warning',
          showCancelButton: true,
          // confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Yes, delete it!',
        },
        function (result) {
          if (result.value) {
            $timeout(function () {
              // Change button text back
              self.button = 'Delete User';
            });
            // Reset the input value
            self.user = null;
            // Call the userFactory delete method
            userFactory.delete(input).then(
              function (result) {
                // Show sweet alert with callback
                sweetAlert.swal(
                  {
                    title: 'Great!',
                    text: 'Your user has been successfully deleted',
                    type: 'success',
                  },
                  function () {
                    // Fetch all the users
                    userFactory.fetch().then(
                      function (result) {
                        self.users = result.users;
                      },
                      function (error) {
                        console.log(error);
                      }
                    );
                  }
                );
              },
              function (error) {
                // Show sweet alert with callback
                sweetAlert.swal({
                  title: 'Oops!',
                  text: error.data.message,
                  type: 'error',
                });
              }
            );
          }
        },
        function (error) {
          $timeout(function () {
            // Change button text back
            self.button = 'Delete User';
          });
        }
      );
    };
  },
});
