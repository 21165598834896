angular
  .module('app')
  .component('dashboardTitle', {
    templateUrl: 'app/dashboard/title.html',
    controllerAs: 'vm',

    controller: function (titleService) {
      // Set the self to this to avoid conflicts
      const self = this;

      self.$onInit = function () {
        self.title = titleService.data.title;
        // self.subTitle = titleService.data.subtitle;
      };
    },
  })
  .service('titleService', function () {
    return this.data;
  });
