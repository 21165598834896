angular.module('app').component('groupResults', {
  templateUrl: 'app/dashboard/game/group-results.html',
  controllerAs: 'vm',
  bindings: {
    gameResultsTerritory: '<',
    gameResults: '<',
  },

  controller: function (
    $rootScope,
    $state,
    $stateParams,
    $localStorage,
    $scope,
    $timeout,
    $http,
    gameFactory,
    sweetAlert,
    titleService,
    Popeye,
    FileSaver,
    Blob
  ) {
    // Set this to self to avoid conflicts
    const self = this;

    // Set the basic controller data to be passed to title service
    titleService.data = {
      title: 'Game Results',
      subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc justo augue, placerat quis nisi sit amet, feugiat tristique mi.',
    };

    // Build and download a PDF report
    self.pdfButton = 'PDF';
    self.generatingReport = false;
    self.fetchPDFexport = function (groupId) {
      self.pdfButton = 'Building PDF report...';
      self.generatingReport = true;
      $http({
        method: 'GET',
        url: 'https://api.positioningwithpeople.com/v1/export/group/' + groupId,
        headers: {
          Authorization: 'Bearer ' + $localStorage.token
        },
        responseType: 'arraybuffer'
      })
      .then(function (data) {
        var file = new Blob([data.data], {type: 'application/pdf'});
        FileSaver.saveAs(file, 'report.pdf');

        self.pdfButton = 'PDF';
        self.generatingReport = false;
      });
    };

    // Build a download an exc
    self.sheetButton = 'Spreadsheet';
    self.generatingSheet = false;

    self.fetchSheetExport = function (groupId) {
      self.sheetButton = 'Building spreadsheet...';
      self.generatingSheet = true;

      $http({
        method: 'GET',
        url: 'https://api.positioningwithpeople.com/v1/export/latest/' + groupId,
        headers: {
          Authorization: 'Bearer ' + $localStorage.token
        },
        responseType: 'application/vnd.ms-excel'
      })
      .then(function (data) {
        var a = document.createElement('a');
        a.href = data.data.file;
        a.download = data.data.name;
        document.body.appendChild(a);
        a.click();
        a.remove();

        self.sheetButton = 'Spreadsheet';
        self.generatingSheet = false;
      }, function (error) {
        console.log(error);
      });
    };

    // Broadcast section title to the header
    $rootScope.$broadcast('headerTitle', {
      title: 'Group Management',
      icon: 'fa-pie-chart',
    });

    // Run when component is ready
    self.$onInit = function () {
      // Set the global group user object
      self.globalUsers = self.gameResults[0].user;

      // Set the results view toggle
      self.resultsView = 0;

      // Set the the user role
      self.role = $localStorage.role;

      // Set the default words array of words selected for consolidation
      $scope.arrWords = [];
      self.consolidateButton = 'consolidate';

      // Set the group based on selection
      self.group = $localStorage.group.find(function (group) {
        return group.id === parseInt($stateParams.id, 10);
      });

      // Set the flag to allow facilitator to manage this group
      self.canManage = false;
      var facilitatorArray = self.group.facilitator_id === null ? [] : self.group.facilitator_id.split(',').map(Number);
      // Only allow facilitators of the group , Admins & Super Admins to manage the group
      if (facilitatorArray.includes($localStorage.user.id) || $localStorage.user.role[0].id === 1 || $localStorage.user.role[0].id === 3) {
        self.canManage = true;
      }

      // Set the environment
      self.env = env;
      // Set the archetype clicked tracker
      self.clicked = false;
      // Call the group results handler
      self.groupResultsHandler();
      // Call the territory group results handler
      self.territoryGroupResultsHandler();
    };

    // Handle the results view toggle
    self.resultsViewToggle = function () {
      self.resultsView = self.resultsView === 1 ? 0 : 1;

      // Hide the subview
      self.showOneSubview = false;
      self.showTerritorySubview = false;

      // Remove selected class from all slices
      $('[data-archetype-id]').removeClass('selected');
      $('[data-territory-id]').removeClass('selected');
    };

    // Show the list of users to manage including and excluding
    self.showIncludeUsers = function () {
      // Initiate the user modal
      const modal = Popeye.openModal({
        templateUrl: 'app/dashboard/game/user-management.html',
        controller: [
          '$scope',
          function ($scope) {
            // Define modal scope variables
            $scope.includeUsers = self.globalUsers;
            $scope.toggleButton = 'Select None';
            $scope.canManage = self.canManage;

            // The toggle the select all
            $scope.toggleSelect = function () {
              if ($scope.toggleButton === 'Select All') {
                angular.forEach($scope.includeUsers, function (v, k) {
                  v.included = 1;
                });
                $scope.toggleButton = 'Select None';
              } else {
                angular.forEach($scope.includeUsers, function (v, k) {
                  v.included = 0;
                });
                $scope.toggleButton = 'Select All';
              }
            };

            // Handle the update users
            $scope.updateIncludeUsers = function (scope) {
              // Set the include and exclude arrays
              let include = [];
              let exclude = [];

              // Add the user id to the relevant arrays
              angular.forEach(scope.includeUsers, function (value, key) {
                switch (value.included) {
                  case 0:
                    exclude.push(value.id);
                    break;
                  case 1:
                    include.push(value.id);
                    break;
                  default:
                }
              });
              // Set the include input
              const includeInput = {
                group_id: self.group.id,
                user: include,
              };
              gameFactory.includeUserResults(includeInput).then(
                function (result) {
                  self.includeUserAlert();
                },
                function (error) {
                  console.log(error);
                }
              );

              // Set the exclude input
              const excludeInput = {
                group_id: self.group.id,
                user: exclude,
              };
              gameFactory.excludeUserResults(excludeInput).then(
                function (result) {
                  self.includeUserAlert();
                },
                function (error) {
                  console.log(error);
                }
              );
            };
          },
        ],
      });
    };

    // Show include user alert
    self.includeUserAlert = function () {
      // Close current Modal
      Popeye.closeCurrentModal();
      // Show alert
      sweetAlert
        .swal({
          title: 'Great!',
          text: 'The users have been updated successfully',
          type: 'success',
        })
        .then(
          function () {
            $state.reload();
          },
          function (error) {
            console.log('Error:' + error);
          }
        );
    };

    self.selectedSubgroup = $stateParams.id;
    self.loadSubgroupResults = function (selectedSubgroup) {
      gameFactory
        .fetch({
          id: selectedSubgroup,
        })
        .then(
          function (result) {
            self.gameResults = result.group;
            // Re-build the page
            $timeout(function () {
              // Hide the subview
              self.showOneSubview = false;
              self.groupResultsHandler();
            });
          },
          function (error) {
            console.log(error);
          }
        );

      gameFactory
        .fetchTerritory({
          id: selectedSubgroup,
        })
        .then(
          function (result) {
            self.gameResultsTerritory = result.group;

            // Re-build the page
            $timeout(function () {
              // Hide the subview
              self.showTerritorySubview = false;
              self.territoryGroupResultsHandler();
            });
          },
          function (error) {
            console.log(error);
          }
        );
    };

    // Drag and drop word consolidation
    self.onDrop = function (data, event) {
      // Get custom object data.
      let word = data['json/custom-object']; // {consolidate: 'Dragged Word'}

      // Show warning before consolidating
      sweetAlert.swal(
        {
          title: 'Are you sure?',
          html: 'You are about to consolidate <strong>' + word.consolidate + '</strong> into <strong>' + event.target.innerHTML + '</strong>',
          type: 'info',
          showCancelButton: true,
          confirmButtonText: 'Yes, go ahead!',
        },
        function () {
          // Set the word to consoldiate
          self.consolidatedWord = event.target.innerHTML; // drop target
          // Set the word to replace
          $scope.arrWords = [word.consolidate];
          // Run word consolidation
          self.consolidateWord();
        }
      );
    };

    // Consolidate words function
    self.consolidateWord = function () {
      // Set the input
      const input = {
        word: self.consolidatedWord,
        consolidatedWord: $scope.arrWords,
        group_id: self.group.id,
      };
      // Consolidate words
      gameFactory.consolidateWord(input).then(
        function (result) {
          // Show sweet alert with callback
          sweetAlert.swal(
            {
              title: 'Great!',
              text: 'Word consolidation was successful. The dashboard will update shortly to reflect these changes.',
              type: 'success',
            },
            function () {
              // Reset the words selected for consolidation
              $scope.arrWords = [];
              // Reset the consolidated word input
              self.consolidatedWord = null;
              // // Reload the state to update chart
              // $state.reload();

              // Refresh group results
              self.refreshGroupResults();
            }
          );
        },
        function (error) {
          console.log(error);
        }
      );
    };

    // Refresh the groups results
    self.refreshGroupResults = function () {
      gameFactory
        .fetch({
          id: self.group.id,
        })
        .then(
          function (result) {
            self.gameResults = result.group;

            // Re-build the page
            $timeout(function () {
              // Hide the subview
              self.showOneSubview = false;

              self.groupResultsHandler();
            });
          },
          function (error) {
            console.log(error);
          }
        );

      gameFactory
        .fetchTerritory({
          id: self.group.id,
        })
        .then(
          function (result) {
            self.gameResultsTerritory = result.group;

            // Re-build the page
            $timeout(function () {
              // Hide the subview
              self.showTerritorySubview = false;
              self.territoryGroupResultsHandler();
            });
          },
          function (error) {
            console.log(error);
          }
        );
    };

    // Main function to render group territory results
    self.territoryGroupResultsHandler = function () {
      self.territoryData = [];

      let clusterDataObject = null;

      const event = {
        click: function (event) {
          // Set the slice to avoid conflicts
          const slice = this;

          // Remove selected class from all slices
          $('[data-territory-id]').removeClass('selected');
          // Add selected class to current slice
          $('[data-territory-id="' + slice.id + '"]').addClass('selected');

          // Track the click event to avoid running an infinite loop
          if (!self.clicked) {
            self.clicked = true;
            $timeout(function () {
              // Trigger the territory profile container click
              $('.territory-profile-container[data-territory-id="' + slice.id + '"]').trigger('click');
            });
          }
        },
      };

      // Set the archetype color for each user
      // angular.forEach(self.gameResultsTerritory[0].clusterOne.archetype1.users, function (value, key) {
      // 	value.archetypeColor = self.gameResultsTerritory[0].clusterOne.archetype1.archetype.color;
      // });
      // angular.forEach(self.gameResultsTerritory[0].clusterOne.archetype2.users, function (value, key) {
      // 	value.archetypeColor = self.gameResultsTerritory[0].clusterOne.archetype2.archetype.color;
      // });
      // set the cluster data

      clusterDataObject = {
        id: 1,
        y: self.gameResultsTerritory[0].clusterOne.ethos.percentage,
        color: self.gameResultsTerritory[0].clusterOne.color,
        ethos: self.gameResultsTerritory[0].clusterOne.ethos,
        expertiseWords: self.gameResultsTerritory[0].clusterOne.expertiseWords,
        valueWords: self.gameResultsTerritory[0].clusterOne.valueWords,
        events: event,
      };
      // Push to data array
      self.territoryData.push(clusterDataObject);

      // Set the archetype color for each user
      // angular.forEach(self.gameResultsTerritory[0].clusterTwo.archetype1.users, function (value, key) {
      // 	value.archetypeColor = self.gameResultsTerritory[0].clusterTwo.archetype1.archetype.color;
      // });
      // angular.forEach(self.gameResultsTerritory[0].clusterTwo.archetype2.users, function (value, key) {
      // 	value.archetypeColor = self.gameResultsTerritory[0].clusterTwo.archetype2.archetype.color;
      // });
      // set the cluster data
      clusterDataObject = {
        id: 2,
        y: self.gameResultsTerritory[0].clusterTwo.ethos.percentage,
        color: self.gameResultsTerritory[0].clusterTwo.color,
        ethos: self.gameResultsTerritory[0].clusterTwo.ethos,
        expertiseWords: self.gameResultsTerritory[0].clusterTwo.expertiseWords,
        valueWords: self.gameResultsTerritory[0].clusterTwo.valueWords,
        events: event,
      };
      // Push to data array
      self.territoryData.push(clusterDataObject);

      // Set the archetype color for each user
      // angular.forEach(self.gameResultsTerritory[0].clusterThree.archetype1.users, function (value, key) {
      // 	value.archetypeColor = self.gameResultsTerritory[0].clusterThree.archetype1.archetype.color;
      // });
      // angular.forEach(self.gameResultsTerritory[0].clusterThree.archetype2.users, function (value, key) {
      // 	value.archetypeColor = self.gameResultsTerritory[0].clusterThree.archetype2.archetype.color;
      // });
      // set the cluster data
      clusterDataObject = {
        id: 3,
        y: self.gameResultsTerritory[0].clusterThree.ethos.percentage,
        color: self.gameResultsTerritory[0].clusterThree.color,
        ethos: self.gameResultsTerritory[0].clusterThree.ethos,
        expertiseWords: self.gameResultsTerritory[0].clusterThree.expertiseWords,
        valueWords: self.gameResultsTerritory[0].clusterThree.valueWords,
        events: event,
      };
      // Push to data array
      self.territoryData.push(clusterDataObject);

      // Set the archetype color for each user
      // angular.forEach(self.gameResultsTerritory[0].clusterFour.archetype1.users, function (value, key) {
      // 	value.archetypeColor = self.gameResultsTerritory[0].clusterFour.archetype1.archetype.color;
      // });
      // angular.forEach(self.gameResultsTerritory[0].clusterFour.archetype2.users, function (value, key) {
      // 	value.archetypeColor = self.gameResultsTerritory[0].clusterFour.archetype2.archetype.color;
      // });
      // set the cluster data
      clusterDataObject = {
        id: 4,
        y: self.gameResultsTerritory[0].clusterFour.ethos.percentage,
        color: self.gameResultsTerritory[0].clusterFour.color,
        ethos: self.gameResultsTerritory[0].clusterFour.ethos,
        expertiseWords: self.gameResultsTerritory[0].clusterFour.expertiseWords,
        valueWords: self.gameResultsTerritory[0].clusterFour.valueWords,
        events: event,
      };

      // Push to data array
      self.territoryData.push(clusterDataObject);

      // Build territory pie chart
      self.buildGameOne(1, '.game-territory-container', self.territoryData);
    };

    // Main function to render group game results
    self.groupResultsHandler = function () {
      const groupID = self.group.id;

      self.groupUserCount = self.group.user.length;

      self.gameOneData = [];
      self.gameTwoData = [];
      self.gameTwoWords = [];
      self.gameThreeData = [];
      self.gameThreeBarData = [];
      self.gameThreeWords = [];

      // Set the ethos
      self.ethos = self.gameResults[0].valueGameResults;

      let gameOneDataObject = null;
      let gameOneUserCount = 0;
      let gameTwoDataObject = null;
      let gameThreeDataObject = null;
      let gameThreeUserCount = 0;
      let gameThreeBarDataObject = null;

      // Clean up
      self.archetype = null;
      self.word = null;
      self.wordUsers = [];
      self.barWordUsers = [];

      // Handle the Game One results here
      angular.forEach(self.gameResults[0].contributionGameResults, function (value, key) {
        if (value.archetype) {
          // Get the user count per archetype
          let userList = angular.isUndefined(value.users) ? {} : value.users;
          let keys = Object.keys(userList);

          gameOneUserCount += keys.length;

          // Build the Game One data object
          gameOneDataObject = {
            id: value.archetype.id,
            answer: value.archetype.answer,
            name: value.archetype.title,
            color: value.archetype.color,
            info: value.archetype.info,
            users: value.users,
            y: keys.length,
            events: {
              click: function (event) {
                // Set the slice to avoid conflicts
                const slice = this;

                // Remove selected class from all slices
                $('[data-archetype-id]').removeClass('selected');
                // Add selected class to current slice
                $('[data-archetype-id="' + slice.id + '"]').addClass('selected');

                // Track the click event to avoid running an infinite loop
                if (!self.clicked) {
                  self.clicked = true;
                  $timeout(function () {
                    // Trigger the archetype profile container click
                    $('.archetype-profile-container[data-archetype-id="' + slice.id + '"]').trigger('click');
                  });
                }
              },
            },
          };
          // Add data object to data array
          self.gameOneData.push(gameOneDataObject);
        }
      });

      let limit = 10; // Set the word display limit
      let count = 0; // Track the limit

      // Set the words without a quadrant
      self.assignQaudrantWords = [];
      // Set the words for consolidtion
      self.consolidationWords = [];

      // Handle the Game Two results here
      angular.forEach(self.gameResults[0].expertiseGameResults.words, function (value, key) {
        // Set quadrant colors
        const quadrantColors = ['#ee4d83', '#e0762c', '#5296c4', '#009e59'];

        // Check if database_word exists
        if (value.database_word) {
          // Build array of words that have not been consolidated
          self.consolidationWords.push(value.word);

          // Add the color of the qords quadrant to the word object
          if (value.database_word[0].quadrant) {
            value.database_word[0].color = quadrantColors[value.database_word[0].quadrant - 1];
          }

          // Build array of all words to allow re-assigning to quadrant
          value.database_word[0].word = value.word;
          self.assignQaudrantWords.push(value.database_word[0]);

          count++; // Set counter to only display top 10 results
          if (count <= limit) {
            // Set the game words
            self.gameTwoWords.push(value.word);
            // Build the Game One data object
            gameTwoDataObject = {
              color: value.database_word[0].quadrant ? quadrantColors[value.database_word[0].quadrant - 1] : '#cccccc',
              y: value.count,
              users: value.users,
              events: {
                click: function (event) {
                  const slice = this;
                  $scope.$evalAsync(function () {
                    self.wordColor = slice.color;
                    self.wordUsers = slice.users;
                    self.word = value.word;
                  });
                },
              },
            };
            // Add data object to data array
            self.gameTwoData.push(gameTwoDataObject);
          }
        }

        // Create  list of all expertise word users
        self.wordUsers = self.wordUsers.concat(value.users);
      });

      // Get array of unique users
      self.wordUsers = unique(self.wordUsers);

      // Handle the Game three results here
      angular.forEach(self.gameResults[0].valueGameResults, function (value, key) {
        // Check if ethos or words
        if (value.ethos) {
          // Get the user count per ethos
          let userList = angular.isUndefined(value.users) ? {} : value.users;
          let keys = Object.keys(userList);

          gameThreeUserCount += keys.length;

          // Build the Game three data object
          gameThreeDataObject = {
            name: value.ethos.name,
            color: value.ethos.color,
            y: keys.length,
            users: value.users,
          };

          // Add data object to data array
          self.gameThreeData.push(gameThreeDataObject);
        } else {
          angular.forEach(value, function (v, k) {
            if (v.word) {
              // Set the game three words
              self.gameThreeWords.push(v.word);
              // Build the Game three bar data object
              gameThreeBarDataObject = {
                color: v.color,
                y: v.count,
                users: v.users,
                events: {
                  click: function (event) {
                    const slice = this;
                    $scope.$evalAsync(function () {
                      self.barWordColor = slice.color;
                      self.barWordUsers = slice.users;
                      self.barWord = v.word;
                    });
                  },
                },
              };
              // Add data object to data array
              self.gameThreeBarData.push(gameThreeBarDataObject);

              // Create  list of all expertise word users
              self.barWordUsers = self.barWordUsers.concat(v.users);
            }
          });
        }
      });

      // Get array of unique users
      self.barWordUsers = unique(self.barWordUsers);

      // Build Game One only if there are users attached
      if (gameOneUserCount > 0) {
        if (self.gameOneData.length > 0) {
          self.buildGameOne(0, '.game-one-container', self.gameOneData);
        }
      } else {
        // Show sweet alert with callback
        sweetAlert.swal({
          title: 'Oops!',
          text: 'There are no results for the Contribution Game',
          type: 'warning',
        });
      }
      // Build game two only if there are words
      if (self.gameTwoWords.length > 0) {
        // Build Super Game Two
        self.buildGameTwo(0, '.game-two-container', self.gameTwoData, self.gameTwoWords, '#f9f9f9', 25);
      } else {
        // Show sweet alert with callback
        sweetAlert.swal({
          title: 'Oops!',
          text: 'There are no results for the Expertise Game',
          type: 'warning',
        });
      }

      // Build Game Three Bar  only if there are words available
      if (self.gameThreeWords.length > 0) {
        self.buildGameTwo(2, '.game-three-bar-container', self.gameThreeBarData, self.gameThreeWords, '#f9f9f9', 15);
      } else {
        // Show sweet alert with callback
        sweetAlert.swal({
          title: 'Oops!',
          text: 'There are no results for the Value Game',
          type: 'warning',
        });
      }

      // Build Game Three only if there are users attached
      if (gameThreeUserCount > 0) {
        if (self.gameThreeData.length > 0) {
          self.buildGameThree(0, '.game-three-container', self.gameThreeData, '#fff');
        }
      } else {
        // Destroy previous charts
        // if (self.gameThreeChart.length === 0) {
        // 	self.gameThreeChart.destroy();
        // }
        // Show sweet alert with callback
        sweetAlert.swal({
          title: 'Oops!',
          text: 'There is no game data available for Game Three',
          type: 'warning',
        });
      }
    };

    // Return array of unique objects
    function unique(a) {
      let unique = [];
      a.forEach(function (d) {
        let found = false;
        unique.forEach(function (u) {
          if (u.id === d.id) {
            found = true;
          }
        });
        if (!found) {
          unique.push(d);
        }
      });
      return unique;
    }

    // Set gameOneChart as an array to store multiple games
    self.gameOneChart = [];
    /* Build Game One function  */
    self.buildGameOne = function (index, container, data) {
      // Destroy previous charts
      if (self.gameOneChart[index]) {
        self.gameOneChart[index].destroy();
      }
      // Remove previous text object from framework
      $('.framework-percentage').remove();
      // Set the gameOneContainer
      self.gameOneContainer = $(container);
      /* Game one chart */
      self.gameOneChart[index] = new Highcharts.Chart({
        chart: {
          renderTo: self.gameOneContainer[0],
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
          backgroundColor: '#f9f9f9',
        },
        title: {
          text: '',
        },
        tooltip: {
          enabled: false,
          backgroundColor: 'white',
          borderRadius: 15,
          shadow: false,
          padding: 20,
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false,
            },
            showInLegend: false,
          },
        },
        series: [
          {
            name: 'Users',
            colorByPoint: true,
            innerSize: '25%',
            data: data, // Set the game one chart data here
            borderWidth: 2,
          },
        ],
      });
      /* Game One Chart End */

      // This is used to build the archetype view
      if (self.gameOneChart[0]) {
        self.gameOneResults = self.gameOneChart[0].series[0].data;
      }

      // This is used to build the territory view
      if (self.gameOneChart[1]) {
        self.territoryResults = self.gameOneChart[1].series[0].data;

        // Loop through territory data and bind events and percentages
        angular.forEach(self.gameOneChart[1].series[0].data, function (value, key) {
          let obj = $('[data-territory-id="' + value.id + '"]');

          // Get the percantage
          let percentage = parseFloat(value.ethos.percentage.toFixed(0));
          if (percentage === 0) {
            percentage = ' ' + percentage;
          }

          // Create an tvg text element
          let textObject = document.createElementNS('http://www.w3.org/2000/svg', 'text');
          textObject.setAttributeNS(null, 'x', 10);
          textObject.setAttributeNS(null, 'y', 10);
          textObject.setAttributeNS(null, 'class', 'framework-percentage');
          textObject.innerHTML = percentage + '%';

          percentage = 0;

          // Add new text object to the framework slice
          obj.append(textObject);

          // Unbind any previous event handlers
          obj.off('click');
          // Bind click event to framework slice to trigger the click event on pie chart slice and vice versa
          obj.on('click', function (e) {
            // Track the click event to avoid running an infinite loop
            if (!self.clicked) {
              // Fire the slice click event
              self.gameOneChart[1].series[0].data[key].firePointEvent('click');
              self.clicked = true;
            }
          });
        });
      } else {
        // Loop through Game One data to and bind events and percentages
        angular.forEach(self.gameOneChart[0].series[0].data, function (value, key) {
          // Set the relevant framwork slice object element
          let obj = $('[data-archetype-id="' + value.options.id + '"]');

          // Get the percantage
          let percentage = parseFloat(value.percentage.toFixed(0));
          if (percentage === 0) {
            percentage = ' ' + percentage;
          }
          // Create an tvg text element
          let textObject = document.createElementNS('http://www.w3.org/2000/svg', 'text');
          textObject.setAttributeNS(null, 'x', 10);
          textObject.setAttributeNS(null, 'y', 10);
          textObject.setAttributeNS(null, 'class', 'framework-percentage');
          textObject.innerHTML = percentage + '%';

          // Add new text object to the framework slice
          obj.append(textObject);

          // Unbind any previous event handlers
          obj.off('click');
          // Bind click event to framework slice to trigger the click event on pie chart slice and vice versa
          obj.on('click', function (e) {
            // Track the click event to avoid running an infinite loop
            if (!self.clicked) {
              // Fire the slice click event
              self.gameOneChart[0].series[0].data[key].firePointEvent('click');
              self.clicked = true;
            }
          });
        });
      }
    };
    /* End build Game One function  */

    /* Territory sub view */
    self.territorySelectHandler = function (scope) {
      // Remove the selected class from the Game One Subview Child
      $('.game-one-sub-subview').removeClass('visible');

      // Handle territory sub view animation
      self.showTerritorySubview = true;

      // Set the territory id
      self.territoryName = scope.data.ethos.ethos.name;
      self.territoryID = scope.data.id;
      // Set the territorty color
      self.territoryColor = scope.data.color;
      // set the archetype 1 & 2 data
      self.ethos = scope.data.ethos;

      // Game One sub three variables
      self.territorySubTwoData = [];
      self.territorySubTwoWords = [];
      self.territorySubThreeWords = [];
      self.territorySubThreeBarData = [];

      let territorySubTwoDataObject = null;
      let limit = 5; // Set the word display limit
      let count = 0; // Track the limit

      // Game one sub three variables
      self.territorySubThreeData = [];

      let territorySubThreeDataObject = null;
      let territorySubThreeBarDataObject = null;
      let territorySubThreeUserCount = 0;

      // Handle the Game One subview Two results here
      angular.forEach(scope.data.expertiseWords, function (value, key) {
        // Set quadrant colors
        const quadrantColors = ['#ee4d83', '#e0762c', '#5296c4', '#009e59'];

        if (value.database_word) {
          count++; // Set counter to only display top 10 results
          if (count <= limit) {
            // Set the game words
            self.territorySubTwoWords.push(value.word);
            // Build the Game One data object
            territorySubTwoDataObject = {
              color: value.database_word[0].quadrant ? quadrantColors[value.database_word[0].quadrant - 1] : '#cccccc',
              y: value.count,
            };
            // Add data object to data array
            self.territorySubTwoData.push(territorySubTwoDataObject);
          }
        }
      });

      // Reset the count
      count = 0;

      // Check if there are any words
      if (scope.data.expertiseWords.length > 0) {
        // Build territory sub two
        self.buildGameTwo(4, '.territory-sub-two-container', self.territorySubTwoData, self.territorySubTwoWords, '#f9f9f9', 50);
      } else {
        self.ethos.expertiseWordsMessage = 'There are no expertise words';
      }
      // Handle theterritory sub three results here
      angular.forEach(scope.data.valueWords, function (value, key) {
        count++;
        if (count <= limit) {
          // Limit to 5 words
          // Set the game three words
          self.territorySubThreeWords.push(value.word);
          // Build the Game three bar data object
          territorySubThreeBarDataObject = {
            color: value.color,
            y: value.count,
          };
          // Add data object to data array
          self.territorySubThreeBarData.push(territorySubThreeBarDataObject);
        }
      });
      // Check if there are any words
      if (scope.data.valueWords.length > 0) {
        // Build territory sub two
        self.buildGameTwo(5, '.territory-sub-three-container', self.territorySubThreeBarData, self.territorySubThreeWords, '#f9f9f9', 50);
      } else {
        self.ethos.valueWordsMessage = 'There are no value words';
      }

      // Track the click event to avoid running an infinite loop
      if (!self.clicked) {
        self.clicked = true;
        // Fire the slice click event
        self.gameOneChart[1].series[0].data[scope.data.index].firePointEvent('click');
      }
    };

    /* Game one sub view */
    self.archetypeSelectHandler = function (scope) {
      // Destroy previous charts
      if (self.gameTwoChart[1]) {
        self.gameTwoChart[1].destroy();
        self.gameTwoChart[1] = null;
      }
      if (self.gameTwoChart[3]) {
        self.gameTwoChart[3].destroy();
        self.gameTwoChart[3] = null;
      }

      // Checks if archetype has results
      if (scope.data.percentage === 0) {
        sweetAlert.swal({
          title: 'Oops!',
          text: 'There is no data available for this Archetype!',
          type: 'warning',
        });
      } else {
        const groupID = self.group.id;
        const answer = scope.data.answer;

        // Remove the selected class from the Game One Subview Child
        $('.game-one-sub-subview').removeClass('visible');

        // Set the selected archetype
        self.subOneArchetype = scope.data.name;
        // Set the selected archetype info
        self.subOneArchetypeInfo = scope.data.info;
        // Set the archetype color
        self.archetypeColor = scope.data.color;
        // Set the archetype users
        self.archetypeUsers = unique(scope.data.users);

        // Track the click event to avoid running an infinite loop
        if (!self.clicked) {
          self.clicked = true;
          // Fire the slice click event
          self.gameOneChart[0].series[0].data[scope.data.index].firePointEvent('click');
        }
        const input = {
          id: groupID,
          answer: answer,
        };
        // Call the gameFactory fetchUsersGameResults method
        gameFactory.fetchGameOneSub(input).then(function (result) {
          // Game One sub three variables
          self.gameOneSubTwoData = [];
          self.gameOneSubTwoWords = [];
          self.gameOneSubThreeWords = [];
          self.gameOneSubThreeBarData = [];

          let gameOneSubTwoDataObject = null;
          let limit = 5; // Set the word display limit
          let count = 0; // Track the limit

          // Game one sub three variables
          self.gameOneSubThreeData = [];

          let gameOneSubThreeDataObject = null;
          let gameOneSubThreeBarDataObject = null;
          let gameOneSubThreeUserCount = 0;

          // Handle the Game One subview Two results here
          angular.forEach(result.group[0].expertiseGameResults.words, function (value, key) {
            // Set quadrant colors
            const quadrantColors = ['#ee4d83', '#e0762c', '#5296c4', '#009e59'];

            if (value.database_word) {
              count++; // Set counter to only display top 10 results
              if (count <= limit) {
                // Set the game words
                self.gameOneSubTwoWords.push(value.word);
                // Build the Game One data object
                gameOneSubTwoDataObject = {
                  color: value.database_word[0].quadrant ? quadrantColors[value.database_word[0].quadrant - 1] : '#cccccc',
                  y: value.count,
                };
                // Add data object to data array
                self.gameOneSubTwoData.push(gameOneSubTwoDataObject);
              }
            }
          });

          // Check if there are any words
          if (result.group[0].expertiseGameResults.words || result.group[0].expertiseGameResults.words.length > 0) {
            // Build game one sub two
            self.buildGameTwo(1, '.game-one-sub-two-container', self.gameOneSubTwoData, self.gameOneSubTwoWords, '#f9f9f9', 50);
          }
          // Handle the Game one sub three results here
          angular.forEach(result.group[0].valueGameResults, function (value, key) {
            // Check if ethos or words
            if (!value.ethos) {
              angular.forEach(value, function (v, k) {
                if (k <= 4) {
                  // Limit to 5 words
                  // Set the game three words
                  self.gameOneSubThreeWords.push(v.word);
                  // Build the Game three bar data object
                  gameOneSubThreeBarDataObject = {
                    color: v.color,
                    y: v.count,
                    users: v.users,
                  };
                  // Add data object to data array
                  self.gameOneSubThreeBarData.push(gameOneSubThreeBarDataObject);
                }
              });
            }
          });
          self.buildGameTwo(3, '.game-one-sub-three-container', self.gameOneSubThreeBarData, self.gameOneSubThreeWords, '#f9f9f9', 50);
        });

        $('[data-toggle="tooltip"]').tooltip();

        // Handle sub view animation
        self.showOneSubview = true;
      }
    };
    /* End Game one sub view */

    // Set gameTwoChart as an array to store multiple games
    self.gameTwoChart = [];
    /* Build Game Two function  */
    self.buildGameTwo = function (index, container, data, words, bgColor, pointWidth) {
      // Destroy previous charts
      if (self.gameTwoChart[index]) {
        self.gameTwoChart[index].destroy();
      }
      self.gameTwoContainer = $(container);
      /* Game two chart */
      self.gameTwoChart[index] = new Highcharts.Chart({
        chart: {
          renderTo: self.gameTwoContainer[0],
          type: 'bar',
          backgroundColor: bgColor,
        },
        title: {
          text: '',
        },
        tooltip: {
          enabled: false,
        },
        xAxis: {
          categories: words,
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Weighted Word Count',
          },
        },
        legend: {
          reversed: true,
        },
        plotOptions: {
          bar: {
            colorByPoint: true,
            allowPointSelect: false,
            cursor: 'pointer',
            dataLabels: {
              enabled: false,
            },
            showInLegend: false,
            groupPadding: 0.1,
            pointWidth: pointWidth,
          },
        },
        colors: ['#189c98', '#5e2f62', '#d93f72', '#089555', '#A2C629', '#e0762c', '#FC0', '#5296c4'],
        series: [
          {
            colorByPoint: true,
            data: data,
          },
        ],
      });
      /* Game Two Chart End */
      $timeout(function () {
        // self.gameTwoChart[index].series[0].data[0].firePointEvent('click');
      });
    };
    /* End build Game Two function  */

    // Set gameTwoChart as an array to store multiple games
    self.gameThreeChart = [];
    /* Build Game Three function  */
    self.buildGameThree = function (index, container, data, bgColor) {
      self.gameThreeContainer = $(container);
      /* Game three chart */
      self.gameThreeChart[index] = new Highcharts.Chart({
        chart: {
          renderTo: self.gameThreeContainer[0],
          plotBackgroundColor: null,
          backgroundColor: '#f9f9f9',
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
        },
        title: {
          text: '',
        },
        tooltip: {
          backgroundColor: 'white',
          borderRadius: 15,
          shadow: false,
          padding: 20,
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
        },
        plotOptions: {
          pie: {
            allowPointSelect: false,
            cursor: 'pointer',
            dataLabels: {
              enabled: false,
            },
            showInLegend: false,
          },
        },
        series: [
          {
            name: 'Users',
            colorByPoint: true,
            // innerSize: '45%',
            data: data, // Set the game three chart data here
            borderWidth: 3,
          },
        ],
      });
      /* Game One Chart End */

      $timeout(function () {
        self.gameThreeChart[index].series[0].data[0].firePointEvent('click');
      });
    };
    /* End build Game Three function  */

    /* User profile select Handler */
    self.userProfileSelectHandler = function (scope, tab) {
      // Set the selected user
      self.gameOneSubSubviewUser = scope.user;
      // Handle sub view animation
      self.showOneSubSubview = true;

      // Set the input
      const input = {
        groupID: self.group.id,
        userID: scope.user.id,
      };

      // Call the gameFactory fetchUsersGameResults
      gameFactory.fetchUsersGameResults(input).then(function (result) {
        angular.forEach(self.globalUsers, function (value, key) {
          // Match selected user from global user list
          if (value.id === scope.user.id) {
            // Add the included flag to scope.user object
            scope.user.included = value.included;
          }
        });
        // Initiate the user modal
        const modal = Popeye.openModal({
          templateUrl: 'app/dashboard/game/user-results.html',
          controller: [
            '$scope',
            function ($scope) {
              // Define modal scope variables
              $scope.user = scope.user;
              $scope.result = result.group[0];
              $scope.tab = tab;
              $scope.env = self.env;
              // Handle including or excluding user results
              $scope.includeToggle = function () {
                const input = {
                  group_id: self.group.id,
                  user: $scope.user.id,
                };
                gameFactory.excludeUserResults(input).then(
                  function (result) {
                    self.includeUserAlert();
                  },
                  function (error) {
                    console.log(error);
                  }
                );
                // **********************************
              };
            },
          ],
        });
      });
    };
    /* End User profile select Handler */
  },
});
