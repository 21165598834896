angular
  .module('app')
  .factory('groupFactory', function ($http, $localStorage, __env) {
    return {
      /**
			 * Get group information by group id
			 */
      get: function (input) {
        if (__env.enableDebug) {
          console.log('groupFactory.get ran **********');
        }
        return $http({
          method: 'GET',
          url: __env.apiUrl + 'group/' + input.id,
          headers: {
            Authorization: 'Bearer ' + $localStorage.token
          },
        })
          .then(function (response) {
            return response.data;
          }).then(function (error) {
            return error;
          });
      },
      /* end get */
      /**
			 * Fetch groups
			 */
      fetch: function () {
        if (__env.enableDebug) {
          console.log('groupFactory.fetch ran **********');
        }
        return $http({
          method: 'GET',
          url: __env.apiUrl + 'group/user',
          headers: {
            Authorization: 'Bearer ' + $localStorage.token
          }
        })
          .then(function (response) {
            return response.data;
          }).then(function (error) {
            return error;
          });
      },
      /* end fetch */

      /**
			 * Fetch pending invites
			 */
      fetchPendingInvites: function (input) {
        if (__env.enableDebug) {
          console.log('groupFactory.fetchPendingInvites');
        }
        return $http({
          method: 'GET',
          url: __env.apiUrl + 'invites/' + input.id,
          headers: {
            Authorization: 'Bearer ' + $localStorage.token
          }
        })
          .then(function (response) {
            return response.data;
          }).then(function (error) {
            return error;
          });
      },
      /* end fetch pending invites */

      /**
			 * Create a new group
			 */
      create: function (input) {
        if (__env.enableDebug) {
          console.log('groupFactory.create ran **********');
        }
        return $http({
          method: 'POST',
          url: __env.apiUrl + 'group',
          headers: {
            Authorization: 'Bearer ' + $localStorage.token
          },
          data: input
        })
          .then(function (response) {
            return response.data;
          }).then(function (error) {
            return error;
          });
      },
      /* end create */

      /**
			 * Delete a group
			 */
      delete: function (input) {
        if (__env.enableDebug) {
          console.log('groupFactory.delete ran **********');
        }
        return $http({
          method: 'DELETE',
          url: __env.apiUrl + 'group/' + input.id,
          headers: {
            Authorization: 'Bearer ' + $localStorage.token
          }
        })
          .then(function (response) {
            return response.data;
          }).then(function (error) {
            return error;
          });
      },
      /* end delete */

      /**
			 * Assign a group
			 */
      assign: function (input) {
        if (__env.enableDebug) {
          console.log('groupFactory.assign ran **********');
        }
        return $http({
          method: 'POST',
          url: __env.apiUrl + 'group/user',
          headers: {
            Authorization: 'Bearer ' + $localStorage.token
          },
          data: input
        })
          .then(function (response) {
            return response.data;
          }).then(function (error) {
            return error;
          });
      },
      /* end assign */

      /**
			 * Assign list of users to group
			 */
      assignList: function (input) {
        if (__env.enableDebug) {
          console.log('groupFactory.assign ran **********');
        }
        return $http({
          method: 'POST',
          url: __env.apiUrl + 'import',
          headers: {
            Authorization: 'Bearer ' + $localStorage.token
          },
          data: input
        })
          .then(function (response) {
            return response.data;
          }).then(function (error) {
            return error;
          });
      },
      /* end assign list */

      /**
			 * Update group game order
			 */
      updateGameOrder: function (input) {
        if (__env.enableDebug) {
          console.log('groupFactory.updateGameOrder ran **********');
        }
        return $http({
          method: 'POST',
          url: __env.apiUrl + 'order',
          headers: {
            Authorization: 'Bearer ' + $localStorage.token
          },
          data: input
        })
          .then(function (response) {
            return response.data;
          }).then(function (error) {
            return error;
          });
      },
      /* end assign list */

      addSubs: function (input) {
        if (__env.enableDebug) {
          console.log('groupFactory.addSubs ran **********');
        }
        return $http({
          method: 'POST',
          url: __env.apiUrl + 'subgroup',
          headers: {
            Authorization: 'Bearer ' + $localStorage.token
          },
          data: input
        })
          .then(function (response) {
            return response.data;
          }).then(function (error) {
            return error;
          });
      }
    };
  });
