angular
  .module('app')
  .factory('userFactory', function ($http, $localStorage, __env) {
    return {
      /**
			 * Fetch user
			 */
      fetch: function () {
        if (__env.enableDebug) {
          console.log('userFactory.fetch ran **********');
        }
        return $http({
          method: 'GET',
          url: __env.apiUrl + 'users',
          // url: __env.apiUrl + 'group/user',
          headers: {
            Authorization: 'Bearer ' + $localStorage.token
          }
        })
          .then(function (response) {
            return response.data;
          }).then(function (error) {
            return error;
          });
      },
      /* end fetch */

      /**
			 * Fetch single user
			 */
      fetchSingle: function (id) {
        if (__env.enableDebug) {
          console.log('userFactory.fetchSingle ran **********');
        }
        return $http({
          method: 'GET',
          url: __env.apiUrl + 'user/' + id,
          // url: __env.apiUrl + 'group/user',
          headers: {
            Authorization: 'Bearer ' + $localStorage.token
          }
        })
          .then(function (response) {
            return response.data;
          }).then(function (error) {
            return error;
          });
      },

      /**
			 * Fetch all users and facilitators
			 */
      fetchAll: function () {
        if (__env.enableDebug) {
          console.log('userFactory.fetchAll ran **********');
        }
        return $http({
          method: 'GET',
          url: __env.apiUrl + 'all/users',
          headers: {
            Authorization: 'Bearer ' + $localStorage.token
          }
        })
          .then(function (response) {
            return response.data;
          }).then(function (error) {
            return error;
          });
      },
      /**
			 * Create a new user
			 */
      create: function (input) {
        if (__env.enableDebug) {
          console.log('userFactory.create ran **********');
        }
        return $http({
          method: 'POST',
          url: __env.apiUrl + 'user',
          headers: {
            Authorization: 'Bearer ' + $localStorage.token
          },
          data: input
        })
          .then(function (response) {
            return response.data;
          }).then(function (error) {
            return error;
          });
      },
      /* end create */
      /**
			 * Assign user to group
			 */
      assign: function (input) {
        if (__env.enableDebug) {
          console.log('userFactory.assign ran **********');
        }
        return $http({
          method: 'POST',
          url: __env.apiUrl + 'group/user',
          headers: {
            Authorization: 'Bearer ' + $localStorage.token
          },
          data: input
        })
          .then(function (response) {
            return response.data;
          }).then(function (error) {
            return error;
          });
      },
      /* end create */

      /**
			 * Delete a user
			 */
      delete: function (input) {
        if (__env.enableDebug) {
          console.log('userFactory.delete ran **********');
        }
        return $http({
          method: 'DELETE',
          url: __env.apiUrl + 'admin/user/' + input.id,
          headers: {
            Authorization: 'Bearer ' + $localStorage.token
          }
        })
          .then(function (response) {
            return response.data;
          }).then(function (error) {
            return error;
          });
      },
      /* end delete */

      /**
			 * Update user to user
			 */
      update: function (input) {
        if (__env.enableDebug) {
          console.log('userFactory.update ran **********');
        }
        return $http({
          method: 'POST',
          url: __env.apiUrl + 'user/' + input.id,
          headers: {
            Authorization: 'Bearer ' + $localStorage.token
          },
          data: input
        })
          .then(function (response) {
            return response.data;
          }).then(function (error) {
            return error;
          });
      },
      /* end update */

      /**
			 * Remove user from group
			 */
      remove: function (input) {
        if (__env.enableDebug) {
          console.log('userFactory.remove ran **********');
        }
        return $http({
          method: 'POST',
          url: __env.apiUrl + '/group/remove/user',
          headers: {
            Authorization: 'Bearer ' + $localStorage.token
          },
          data: input
        })
          .then(function (response) {
            return response.data;
          }).then(function (error) {
            return error;
          });
      }
      /* end remove */
    };
  });
