angular.module('app').component('manageUser', {
  templateUrl: 'app/dashboard/user/user-manage.html',
  controllerAs: 'vm',
  bindings: {
    users: '<',
    groups: '<',
    countries: '<',
  },
  controller: function ($rootScope, $stateParams, userFactory, facilitatorFactory, sweetAlert, titleService, $timeout, ngToast) {
    // Set this to self to avoid conflicts
    const self = this;
    // Set the basic controller data to be passed to title service
    titleService.data = {
      title: 'Manage User',
      subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc justo augue, placerat quis nisi sit amet, feugiat tristique mi.',
    };

    // Broadcast section title to the header
    $rootScope.$broadcast('headerTitle', {
      title: 'User Management',
      icon: 'fa-user-o',
    });

    // Run when component is ready
    self.$onInit = function () {
      // Set the initial button state
      self.editButton = 'Update User';
      self.deleteButton = 'Delete User';
      self.assignButton = 'Assign User';

      // Set the selected user
      self.selectedUser = null;

      // Check if we have are coming from user create
      if ($stateParams.user !== null) {
        self.user = [];
        self.user[0] = {};

        // Set our user
        self.user[0].id = $stateParams.user.id;
        self.user[0].name = $stateParams.user.name + ' ' + $stateParams.user.surname + ' | ' + $stateParams.user.email;
        self.user[0].user = $stateParams.user;
      }

      // Set the role object
      self.roles = [
        {
          id: 1,
          role_name: 'Admininstrator',
        },
        {
          id: 4,
          role_name: 'Facilitator',
        },
        {
          id: 2,
          role_name: 'User',
        },
      ];

      // Define user options for multi select
      self.userOptions = [];

      // Sort users names alphabetically
      self.users = self.users.sort(function (a, b) {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
      });
      // Build user options
      angular.forEach(self.users, function (value, key) {
        self.userOptions.push({
          id: value.id,
          name: value.name + ' ' + value.surname + ' | ' + value.email,
          user: value,
        });
      });

      // Define group options for multi select
      self.groupOptions = [];

      // Sort group names alphabetically
      self.groups = self.groups.sort(function (a, b) {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
      });
      // Build group options
      angular.forEach(self.groups, function (value, key) {
        self.groupOptions.push({
          id: value.id,
          group: value.name,
        });
      });
    };

    // Set or unset the user as a facilitator for the group
    self.actionFacilitator = function (action, groupId, userId) {
      const input = {
        group_id: groupId,
        facilitator_id: userId,
      };

      switch (action) {
        default:
        case 'assign':
          facilitatorFactory.assign(input).then(
            function (result) {
              // Toasty poasty
              ngToast.create('User successfully assigned as facilitator...');
              // Update the user info
              self.updateUserInfo(self.user[0].user.id);
            },
            function (error) {
              console.log(error);
            }
          );
          break;
        case 'remove':
          facilitatorFactory.remove(input).then(
            function (result) {
              // Toasty poasty
              ngToast.create('User successfully removed as facilitator...');
              // Update the user info
              self.updateUserInfo(self.user[0].user.id);
            },
            function (error) {
              console.log(error);
            }
          );
          break;
      }
    };

    self.editUserHandler = function () {
      // Change button text
      self.editButton = 'Updating User...';

      // Set the input object
      const input = {
        id: self.user[0].user.id,
        role_id: self.user[0].user.role[0].id,
        name: self.user[0].user.name,
        surname: self.user[0].user.surname,
        email: self.user[0].user.email,
        age: self.user[0].user.age,
        gender: self.user[0].user.gender,
        country: self.user[0].user.country,
      };
      // Call the userFactory edit method
      userFactory.update(input).then(
        function (result) {
          // Change button text back
          self.editButton = 'Update User';
          // Show sweet alert with callback
          sweetAlert.swal({
            title: 'Great!',
            text: 'Your user has been successfully updated',
            type: 'success',
          });
          // Update the user info
          self.updateUserInfo(self.user[0].user.id);
          // Clear the group selection
          self.selection = null;
        },
        function (error) {
          console.log(error);
          // Show sweet alert with callback
          sweetAlert.swal({
            title: 'Oops!',
            text: error.data.message,
            type: 'error',
          });
          // Change button text back
          self.editButton = 'Update User';
        }
      );
    };

    // Handle the user assign
    self.assignUserHandler = function () {
      // Change button text
      self.assignButton = 'Assigning User...';

      // Define selected group id array
      self.selectedGroupID = [];
      // Build group array to send to enpoint
      angular.forEach(self.selection, function (value, key) {
        self.selectedGroupID.push(value.id);
      });

      // Set the input object
      const input = {
        group_id: self.selectedGroupID,
        email: self.user[0].user.email,
      };

      // Call the userFactory create method
      userFactory.assign(input).then(
        function (result) {
          // Change button text back
          self.assignButton = 'Assign User';

          // Show sweet alert with callback
          sweetAlert.swal({
            title: 'Great!',
            text: 'The user was successfully assigned',
            type: 'success',
          });
          // Update the user info
          self.updateUserInfo(self.user[0].user.id);

          // Clear the group selection
          self.selection = null;
        },
        function (error) {
          // Show sweet alert with callback
          sweetAlert.swal({
            title: 'Oops!',
            text: error.data.message,
            type: 'error',
          });
        }
      );
    };

    // Handle user delete handler
    self.deleteUser = function () {
      // Change button text
      self.button = 'Deleting User...';

      // Set the input object
      const input = {
        id: self.user[0].user.id,
      };

      sweetAlert.swal(
        {
          title: 'Are you sure?',
          text: 'You will not be able to recover this user!',
          type: 'warning',
          showCancelButton: true,
          // confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Yes, delete it!',
        },
        function (result) {
          if (result) {
            $timeout(function () {
              // Change button text back
              self.button = 'Delete User';
            });

            // Call the userFactory delete method
            userFactory.delete(input).then(
              function (result) {
                // Show sweet alert with callback
                sweetAlert.swal(
                  {
                    title: 'Great!',
                    text: 'Your user has been successfully deleted',
                    type: 'success',
                  },
                  function () {
                    // Remove user from userOptions  Model
                    angular.forEach(self.userOptions, function (value, key) {
                      if (value.id === self.user[0].user.id) {
                        self.userOptions.splice(key, 1);
                      }
                    });

                    // Reset the user model
                    self.user = null;
                  }
                );
              },
              function (error) {
                // Show sweet alert with callback
                sweetAlert.swal({
                  title: 'Oops!',
                  text: error.data.message,
                  type: 'error',
                });
              }
            );
          }
        },
        function (error) {
          $timeout(function () {
            // Change button text back
            self.button = 'Delete User';
          });
        }
      );
    };

    // Handle the user remove
    self.removeUserFromGroup = function (groupId, i) {
      // Set the input object
      const input = {
        group_id: groupId,
        user_id: self.user[0].user.id,
      };
      // Call the userFactory remove method
      userFactory.remove(input).then(
        function (result) {
          // Show sweet alert with callback
          sweetAlert.swal({
            title: 'Great!',
            text: 'The user was successfully removed',
            type: 'success',
          });

          // Update the user info
          self.updateUserInfo(self.user[0].user.id);
        },
        function (error) {
          // Show sweet alert with callback
          sweetAlert.swal({
            title: 'Oops!',
            text: error.data.message,
            type: 'error',
          });
        }
      );
    };

    self.updateUserInfo = function (userId) {
      // Fetch the users data
      userFactory.fetchSingle(userId).then(function (result) {
        // find the index of the current user
        var foundIndex = self.users.findIndex(function (x) {
          return x.id === userId;
        });

        // Update the current user model
        self.users[foundIndex] = result.user;

        // Define user options for multi select
        self.userOptions = [];

        // Sort users names alphabetically
        self.users = self.users.sort(function (a, b) {
          return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
        });

        // Update the view
        $timeout(function () {
          // Build user options
          angular.forEach(self.users, function (value, key) {
            self.userOptions.push({
              id: value.id,
              name: value.name + ' ' + value.surname + ' | ' + value.email,
              user: value,
            });
          });
          // Update the user
          self.user[0].name = result.user.name + ' ' + result.user.surname + ' | ' + result.user.email;
          self.user[0].user = result.user;
        });
      });
    };
  },
});
