angular.module('app').component('endpointCopy', {
  templateUrl: 'app/dashboard/game/endpoint-copy.html',
  controllerAs: 'vm',
  bindings: {
    copy: '<',
  },

  controller: function ($rootScope, $state, $stateParams, $localStorage, $scope, $timeout, gameFactory, sweetAlert, titleService, Popeye) {
    // Set this to self to avoid conflicts
    const self = this;

    // Set the basic controller data to be passed to title service
    titleService.data = {
      title: 'Endpoint Copy',
      subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc justo augue, placerat quis nisi sit amet, feugiat tristique mi.',
    };

    // Broadcast section title to the header
    $rootScope.$broadcast('headerTitle', {
      title: 'Endpoint Copy',
      icon: 'fa-pencil',
    });

    // Run when component is ready
    self.$onInit = function () {
      // Set the the user role
      self.role = $localStorage.role;
      // Set the environment
      self.env = env;
      // Copy array
      self.endpoints = null;
      // Selected language
      self.language = null;
      self.englishEndpoints = [];
      self.selectedCopy = null;
      self.languages = [];
    };

    // Get Archetypes
    gameFactory.fetchEndpoints({}).then(
      function (result) {
        self.endpoints = result.endpoints;
        angular.forEach(self.endpoints, function (value, key) {
          if (value.language === 'en') {
            self.englishEndpoints.push(value);
          }
          if (self.languages.indexOf(value.language) === -1) {
            self.languages.push(value.language);
          }
        });
        // Re-build the page
        $timeout(function () {});
      },
      function (error) {
        console.log(error);
      }
    );

    self.copySelectHandler = function () {
      self.selectedCopy = [];
      angular.forEach(self.endpoints, function (value, key) {
        if (value.language === self.language) {
          self.selectedCopy.push(value);
        }
      });
    };

    self.saveEndpoint = function (input) {
      gameFactory.saveEndpoint(input).then(
        function (result) {
          sweetAlert.swal({
            title: 'Great!',
            text: result.message,
            type: 'success',
          });
          // Re-build the page
          $timeout(function () {});
        },
        function (error) {
          // Add errors to string
          self.html = Object.keys(error.data.message)
            .map(function (k) {
              return error.data.message[k];
            })
            .join('</br>');
          sweetAlert.swal({
            title: 'Oops!',
            html: self.html,
            type: 'error',
          });
        }
      );
    };
  },
});
