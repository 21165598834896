angular.module('app').component('removeUser', {
  templateUrl: 'app/dashboard/user/user-remove.html',
  controllerAs: 'vm',
  bindings: {
    groups: '<',
  },

  controller: function ($rootScope, userFactory, groupFactory, sweetAlert, titleService) {
    // Set this to self to avoid conflicts
    const self = this;
    // Set the basic controller data to be passed to title service
    titleService.data = {
      title: 'Remove User',
      subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc justo augue, placerat quis nisi sit amet, feugiat tristique mi.',
    };

    // Broadcast section title to the header
    $rootScope.$broadcast('headerTitle', {
      title: 'User Management',
      icon: 'fa-user-o',
    });

    // Run when component is ready
    self.$onInit = function () {
      // Set the initial button state
      self.button = 'Remove User';
    };

    // Handle group select
    self.groupSelectHandler = function () {
      const input = {
        id: self.group.id,
      };
      // Get the users for group
      groupFactory.get(input).then(
        function (result) {
          self.users = result.group[0].user;
        },
        function (error) {
          console.log(error);
          // Show sweet alert with callback
          sweetAlert.swal({
            title: 'Oops!',
            text: error.data.message,
            type: 'error',
          });
        }
      );
    };

    // Handle the user remove
    self.removeUserHandler = function (row) {
      // Set the input object
      const input = row.user.pivot;

      // Call the userFactory remove method
      userFactory.remove(input).then(
        function (result) {
          // Remove user from user object
          self.users.splice(row.index, 1);
          // Show sweet alert with callback
          sweetAlert.swal({
            title: 'Great!',
            text: 'The user was successfully removed',
            type: 'success',
          });
        },
        function (error) {
          console.log(error);
          // Show sweet alert with callback
          sweetAlert.swal({
            title: 'Oops!',
            text: error.data.message,
            type: 'error',
          });
        }
      );
    };
  },
});
