angular.module('app').config(routesConfig);

/** @ngInject */
function routesConfig($stateProvider, $urlRouterProvider, $locationProvider) {
  $locationProvider.html5Mode(true).hashPrefix('!');
  $urlRouterProvider.otherwise('login');

  /* Setup route states */
  /* Default */
  $stateProvider
    .state('login', {
      url: '/login',
      component: 'login',
      restricted: false,
    })
    .state('logout', {
      url: '/logout',
      template: ' ', // Leaving this blank to avoid error
      controller: function (authFactory) {
        authFactory.logout();
      },
      restricted: false,
    })
    .state('reset', {
      url: '/reset',
      component: 'reset',
      restricted: false,
    })
    .state('password', {
      url: '/password?token',
      component: 'password',
      restricted: false,
    })
    .state('dashboard', {
      abstract: true,
      url: '/',
      component: 'dashboard',
      restricted: true,
    })
    .state('dashboard.dashboard', {
      url: 'dashboard',
      component: 'dashboard.dashboard',
      // Resolve below before rendering view
      resolve: {
        // Fetch the global analytics
        analytics: function (dashboardFactory) {
          return dashboardFactory.fetch().then(
            function (result) {
              return result.analytics;
            },
            function (error) {
              console.log(error);
            }
          );
        },
        // Fetch game one analytics
        analyticsOne: function (dashboardFactory) {
          return dashboardFactory.fetchOne().then(
            function (result) {
              return result.analytics;
            },
            function (error) {
              console.log(error);
            }
          );
        },
        // Fetch game two analytics
        analyticsTwo: function (dashboardFactory) {
          return dashboardFactory.fetchTwo().then(
            function (result) {
              return result.analytics;
            },
            function (error) {
              console.log(error);
            }
          );
        },
        // Fetch game three analytics
        analyticsThree: function (dashboardFactory) {
          return dashboardFactory.fetchThree().then(
            function (result) {
              return result.analytics;
            },
            function (error) {
              console.log(error);
            }
          );
        },
      },
      restricted: true,
    });
  /* End Default */

  /* Group */
  $stateProvider
    .state('dashboard.create-group', {
      url: 'create-group',
      component: 'createGroup',
      restricted: true,
      // Resolve below before rendering view
      resolve: {
        // Get the groups
        groups: function (groupFactory) {
          return groupFactory.fetch().then(
            function (result) {
              return result.group;
            },
            function (error) {
              console.log(error);
            }
          );
        },
      },
    })
    .state('dashboard.manage-group', {
      url: 'manage-group',
      component: 'manageGroup',
      params: {
        group: null,
      },
      // Resolve below before rendering view
      resolve: {
        // Get the groups
        groups: function (groupFactory) {
          return groupFactory.fetch().then(
            function (result) {
              return result.group;
            },
            function (error) {
              console.log(error);
            }
          );
        },
        // Get the user list
        users: function (userFactory) {
          // Get the list of users
          return userFactory.fetchAll().then(
            function (result) {
              return result.users;
            },
            function (error) {
              console.log(error);
            }
          );
        },
      },
      restricted: true,
    })
    .state('dashboard.delete-group', {
      url: 'delete-group',
      component: 'deleteGroup',
      // Resolve below before rendering view
      resolve: {
        // Get the groups
        groups: function (groupFactory) {
          return groupFactory.fetch().then(
            function (result) {
              return result.group;
            },
            function (error) {
              return error;
            }
          );
        },
      },
      restricted: true,
    });
  /* End Group */

  /* Facilitator */
  $stateProvider
    .state('dashboard.create-facilitator', {
      url: 'create-facilitator',
      component: 'createFacilitator',
      resolve: {
        countries: function (countryService) {
          return countryService;
        },
      },
      restricted: true,
    })
    .state('dashboard.assign-facilitator', {
      url: 'assign-facilitator',
      component: 'assignFacilitator',
      // Resolve below before rendering view
      resolve: {
        // Get the facilitators
        facilitators: function (facilitatorFactory) {
          return facilitatorFactory.fetch().then(
            function (result) {
              return result.facilitators;
            },
            function (error) {
              console.log(error);
            }
          );
        },
        // Get the groups
        groups: function (groupFactory) {
          return groupFactory.fetch().then(
            function (result) {
              return result.group;
            },
            function (error) {
              console.log(error);
            }
          );
        },
      },
      restricted: true,
    })
    .state('dashboard.edit-facilitator', {
      url: 'edit-facilitator',
      component: 'editFacilitator',
      // Resolve below before rendering view
      resolve: {
        // Get the facilitators
        facilitators: function (facilitatorFactory) {
          return facilitatorFactory.fetch().then(
            function (result) {
              return result.facilitators;
            },
            function (error) {
              console.log(error);
            }
          );
        },
        // Get the countries
        countries: function (countryService) {
          return countryService;
        },
      },
      restricted: true,
    })
    .state('dashboard.remove-facilitator', {
      url: 'remove-facilitator',
      component: 'removeFacilitator',
      // Resolve below before rendering view
      resolve: {
        // Get the facilitators
        facilitators: function (facilitatorFactory) {
          return facilitatorFactory.fetch().then(
            function (result) {
              return result.facilitators;
            },
            function (error) {
              console.log(error);
            }
          );
        },
      },
      restricted: true,
    });
  /* End facilitator */

  /* User */
  $stateProvider
    .state('dashboard.create-user', {
      url: 'create-user',
      component: 'createUser',
      // Resolve below before rendering view
      resolve: {
        // Get the countries
        countries: function (countryService) {
          return countryService;
        },
      },
      restricted: true,
    })
    .state('dashboard.remove-user', {
      url: 'remove-user',
      component: 'removeUser',
      // Resolve below before rendering view
      resolve: {
        // Get the group list
        groups: function (groupFactory) {
          // Get the list of groups
          return groupFactory.fetch().then(
            function (result) {
              return result.group;
            },
            function (error) {
              console.log(error);
            }
          );
        },
      },
      restricted: true,
    })
    .state('dashboard.assign-user', {
      url: 'assign-user',
      component: 'assignUser',
      // Resolve below before rendering view
      resolve: {
        // Get the user list
        users: function (userFactory) {
          // Get the list of users
          return userFactory.fetch().then(
            function (result) {
              return result.users;
            },
            function (error) {
              console.log(error);
            }
          );
        },
        // Get the group list
        groups: function (groupFactory) {
          // Get the list of groups
          return groupFactory.fetch().then(
            function (result) {
              return result.group;
            },
            function (error) {
              console.log(error);
            }
          );
        },
      },
      restricted: true,
    })
    .state('dashboard.manage-user', {
      url: 'manage-user',
      component: 'manageUser',
      params: {
        user: null,
      },
      resolve: {
        // Get the user list
        users: function (userFactory) {
          // Get the list of users
          return userFactory.fetchAll().then(
            function (result) {
              return result.users;
            },
            function (error) {
              console.log(error);
            }
          );
        },
        // Get the group list
        groups: function (groupFactory) {
          // Get the list of groups
          return groupFactory.fetch().then(
            function (result) {
              return result.group;
            },
            function (error) {
              console.log(error);
            }
          );
        },
        // Get the countries
        countries: function (countryService) {
          return countryService;
        },
      },
      restricted: true,
    })
    .state('dashboard.delete-user', {
      url: 'delete-user',
      component: 'deleteUser',
      // Resolve below before rendering view
      resolve: {
        // Get the users
        users: function (userFactory) {
          return userFactory.fetch().then(
            function (result) {
              return result.users;
            },
            function (error) {
              console.log(error);
            }
          );
        },
      },
      restricted: true,
    });
  /* End User */

  /* Group Results */
  $stateProvider
    .state('dashboard.group-results', {
      url: 'group-results/:id',
      component: 'groupResults',
      // Resolve below before rendering view
      resolve: {
        // Get the game results by territory
        gameResultsTerritory: function (gameFactory, $stateParams) {
          return gameFactory
            .fetchTerritory({
              id: $stateParams.id, // Group id form url param
            })
            .then(
              function (result) {
                return result.group;
              },
              function (error) {
                console.log(error);
              }
            );
        },
        // Get the game results by archetype
        gameResults: function (gameFactory, $stateParams) {
          return gameFactory
            .fetch({
              id: $stateParams.id, // Group id form url param
            })
            .then(
              function (result) {
                return result.group;
              },
              function (error) {
                console.log(error);
              }
            );
        },
      },
      restricted: true,
    })
    .state('dashboard.group-dashboard', {
      url: 'group-dashboard',
      component: 'groupDashboard',
      restricted: true,
    })
    .state('dashboard.game-copy', {
      url: 'game-copy',
      component: 'gameCopy',
      restricted: true,
    })

    .state('dashboard.archetype-copy', {
      url: 'archetype-copy',
      component: 'contributionCopy',
      restricted: true,
    })

    .state('dashboard.mailer-copy', {
      url: 'mailer-copy',
      component: 'mailerCopy',
      restricted: true,
    })

    .state('dashboard.endpoint-copy', {
      url: 'endpoint-copy',
      component: 'endpointCopy',
      restricted: true,
    })
    .state('dashboard.ethos-copy', {
      url: 'ethos-copy',
      component: 'valueCopy',
      restricted: true,
    })
    .state('dashboard.value-copy', {
      url: 'value-copy',
      component: 'gameThreeCopy',
      restricted: true,
    })
    .state('dashboard.contribution-copy', {
      url: 'contribution-copy',
      component: 'gameOneCopy',
      restricted: true,
    });
  /* End Group Results */

  /* Export link handler */
  $stateProvider.state('export-pdf', {
    url: env.apiUrl + 'export/group/:id',
  });

  /* Export xls link handler */
  $stateProvider.state('export-spreadsheet', {
    url: env.apiUrl + 'export/latest/:id',
  });
}
