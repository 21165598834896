angular
  .module('app')
  .component('editFacilitator', {
    templateUrl: 'app/dashboard/facilitator/facilitator-edit.html',
    controllerAs: 'vm',
    bindings: {
      facilitators: '<',
      countries: '<'
    },

    controller: function ($rootScope, facilitatorFactory, sweetAlert, titleService) {
      // Set this to self to avoid conflicts
      const self = this;
      // Set the basic controller data to be passed to title service
      titleService.data = {
        title: 'Edit Facilitator',
        subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc justo augue, placerat quis nisi sit amet, feugiat tristique mi.'
      };

      // Broadcast section title to the header
      $rootScope.$broadcast('headerTitle', {
        title: 'Facilitator Management',
        icon: 'fa-user'
      });

      // Run when component is ready
      self.$onInit = function () {
        // Set the initial button state
        self.button = 'Edit Facilitator';
        // Set the role object
        self.roles = [{
          id: 3,
          role_name: 'Super Admin',
        }, {
          id: 1,
          role_name: 'Admininstrator',
        }, {
          id: 4,
          role_name: 'Facilitator',
        }, {
          id: 2,
          role_name: 'User',
        }];
      };

      self.editFacilitatorHandler = function () {
        // Change button text
        self.button = 'Updating Facilitator...';

        // Set the input object
        const input = {
          role: self.facilitator.role[0].id,
          id: self.facilitator.id,
          name: self.facilitator.name,
          surname: self.facilitator.surname,
          email: self.facilitator.email,
          age: self.facilitator.age,
          gender: self.facilitator.gender,
          country: self.facilitator.country
        };
        // Call the facilitatorFactory edit method
        facilitatorFactory.update(input)
          .then(function (result) {
            // Change login button text back
            self.button = 'Edit Facilitator';

            // Show sweet alert with callback
            sweetAlert.swal({
              title: 'Great!',
              text: 'Your facilitator has been successfully updated',
              type: 'success',
            });
          }, function (error) {
            console.log(error);
            // Show sweet alert with callback
            sweetAlert.swal({
              title: 'Oops!',
              text: error.data.message,
              type: 'error',
            });
          });
      };
    }

  });
