angular.module('app').component('createUser', {
  templateUrl: 'app/dashboard/user/user-create.html',
  controllerAs: 'vm',
  bindings: {
    // groups: '<',
    countries: '<',
  },

  controller: function ($rootScope, $state, $timeout, userFactory, sweetAlert, titleService) {
    // Set this to self to avoid conflicts
    const self = this;
    // Set the basic controller data to be passed to title service
    titleService.data = {
      title: 'Create User',
      subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc justo augue, placerat quis nisi sit amet, feugiat tristique mi.',
    };

    // Broadcast section title to the header
    $rootScope.$broadcast('headerTitle', {
      title: 'User Management',
      icon: 'fa-user-o',
    });

    // Run when component is ready
    self.$onInit = function () {
      // Set the initial button state
      self.button = 'Create User';

      // Set the role object
      // self.roles = [{
      // 	id: 2,
      // 	role_name: 'User',
      // }];
    };

    self.createUserHandler = function () {
      // Change button text
      self.button = 'Creating User...';
      // Set the input object
      const input = {
        name: self.name,
        surname: self.surname,
        email: self.email,
        country: self.country,
        age: self.age,
        gender: self.gender,
        // group_id: self.group.id,
        // role_id: self.user.role[0].id
        role_id: 2, // Hard code the user role
      };
      // Call the userFactory create method
      userFactory.create(input).then(
        function (result) {
          // Change button text back
          $timeout(function () {
            self.button = 'Create User';
          });

          // Show sweet alert with callback
          sweetAlert.swal(
            {
              title: 'Great!',
              text: 'Your user has been successfully created',
              type: 'success',
            },
            function () {
              // Rdirect to user edit and send user new user object
              $timeout(function () {
                $state.go('dashboard.manage-user', {
                  user: result.user,
                });
              });
            }
          );
        },
        function (error) {
          // Show sweet alert with callback
          sweetAlert.swal({
            title: 'Oops!',
            text: error.data.message.email,
            type: 'error',
          });

          // Change button text back
          $timeout(function () {
            self.button = 'Create User';
          });
        }
      );
    };
  },
});
