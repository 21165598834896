angular.module('app').component('manageGroup', {
  templateUrl: 'app/dashboard/group/group-manage.html',
  controllerAs: 'vm',
  bindings: {
    groups: '<',
    users: '<',
  },

  controller: function (
    $rootScope,
    $scope,
    $localStorage,
    $stateParams,
    groupFactory,
    userFactory,
    facilitatorFactory,
    sweetAlert,
    titleService,
    Upload,
    $timeout,
    ngToast,
    $compile
  ) {
    // Set this to self to avoid conflicts
    const self = this;

    // Set the basic controller data to be passed to title service
    titleService.data = {
      title: 'Manage Group',
      subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc justo augue, placerat quis nisi sit amet, feugiat tristique mi.',
    };

    // Broadcast section title to the header
    $rootScope.$broadcast('headerTitle', {
      title: 'Group Management',
      icon: 'fa-users',
    });

    self.show = null;
    // Set defaults
    self.toggle = false;
    self.toggleButton = 'Upload User List';
    self.deleteButton = 'Delete Group';

    // Run when component is ready
    self.$onInit = function () {
      // Define the subGroups array
      self.subGroups = [];
      // Set the initial button text
      self.button = 'Invite Users To This Group';
      // Set the initial assign button text
      self.assignButton = 'Assign Users';

      // Check if we have are coming from group create
      if ($stateParams.group !== null) {
        // Set our group
        self.group = [];
        self.group[0] = $stateParams.group;
        // Get the groups pending invites
        self.handleGroupSelect();
      }

      // Set the initial email field
      self.emails = [
        {
          id: 'email-1',
        },
      ];

      // Define group options for multi select
      self.groupOptions = [];

      // Sort group names alphabetically
      self.groups = self.groups.sort(function (a, b) {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
      });

      // Build group options
      angular.forEach(self.groups, function (value, key) {
        // Only add the groups the user is a facilitator of or if Admin or Super Admin
        var facilitatorArray = value.facilitator_id === null ? [] : value.facilitator_id.split(',').map(Number);

        if (facilitatorArray.includes($localStorage.user.id) || $localStorage.user.role[0].id === 1 || $localStorage.user.role[0].id === 3) {
          self.groupOptions.push(value);
        }
      });

      // Define user options for multi select
      self.userOptions = [];

      // Sort users names alphabetically
      self.users = self.users.sort(function (a, b) {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
      });

      // Build user options
      angular.forEach(self.users, function (value, key) {
        self.userOptions.push({
          id: value.id,
          name: value.name + ' ' + value.surname + ' | ' + value.email,
          email: value.email,
        });
      });
    };

    // Set or unset the user as a facilitator for the group
    self.actionFacilitator = function (action, groupId, userId) {
      const input = {
        group_id: groupId,
        facilitator_id: userId,
      };
      switch (action) {
        case 'assign':
          facilitatorFactory.assign(input).then(
            function (result) {
              // Update the group model
              self.updateGroupInfo(groupId);
              // Toasty poasty
              ngToast.create('User successfully assigned as facilitator...');
            },
            function (error) {
              swal('Error', error.data.message, 'error');
            }
          );
          break;
        case 'remove':
          facilitatorFactory.remove(input).then(
            function (result) {
              // Update the group model
              self.updateGroupInfo(groupId);
              // Toasty poasty
              ngToast.create('User successfully removed as facilitator...');
            },
            function (error) {
              swal('Error', error.data.message, 'error');
            }
          );
          break;
        default:
      }
    };

    // Handle the group select change
    self.handleGroupSelect = function () {
      self.pendingInvites = null;
      self.pendingInviteDisplay = null;
      self.pendingInviteMsg = null;
      self.selectedGroupId = self.group[0].id;
      self.gameOrder = [];
      self.selectGroups = [];
      self.selectGroups.push(self.group[0]);

      angular.forEach(self.group[0].sub_groups, function (value, key) {
        self.selectGroups.push(value);
      });

      // Update the DOM game order based on group game order
      $timeout(function () {
        $('.game-order > li')
          .sort(function (a, b) {
            return $(b).data('order') < $(a).data('order') ? 1 : -1;
          })
          .appendTo('.game-order');
      }, 0);

      // Initialise the jquery sortable
      $('.game-order').sortable({
        stop: function (event, ui) {
          // Set the game order and update server
          self.gameOrder[0] = $('.game-order > li').index($('.game-order > li[data-game="1"]')) + 1;
          self.gameOrder[1] = $('.game-order > li').index($('.game-order > li[data-game="2"]')) + 1;
          self.gameOrder[2] = $('.game-order > li').index($('.game-order > li[data-game="3"]')) + 1;

          const input = {
            group_id: self.group[0].id,
            game_order: self.gameOrder,
          };

          // send updated order to server
          groupFactory.updateGameOrder(input).then(
            function (result) {
              ngToast.create('Game order successfully updated...');
            },
            function (error) {
              console.log(error);
            }
          );
        },
      });

      if (angular.isDefined(self.group[0])) {
        // Fetch the groups pending invites
        groupFactory
          .fetchPendingInvites({
            id: self.group[0].id,
          })
          .then(
            function (result) {
              self.pendingInvites = result;
              self.pendingInviteDisplay = true;
            },
            function (error) {
              self.pendingInviteMsg = error.data.message;
              self.pendingInviteDisplay = false;
            }
          );
      }
    };

    // Submit action based on toggle
    self.submitForm = function () {
      if (self.toggle) {
        self.uploadListHandler();
      } else {
        self.assignGroupHandler();
      }
    };

    // Handle toggle  visibility of elements
    self.toggleInputHandler = function () {
      self.toggle = !self.toggle;
      self.toggleButton = self.toggle ? 'Add Email Addresses' : 'Upload User List';
    };

    // Handle group delete
    self.deleteGroup = function () {
      // Change button text
      self.deleteButton = 'Deleting Group...';

      // Set the input object
      const input = {
        id: self.group[0].id,
      };
      // Confirm group delete
      sweetAlert.swal(
        {
          title: 'Are you sure?',
          text: 'You will not be able to recover this group!',
          type: 'warning',
          showCancelButton: true,
          // confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Yes, delete it!',
        },
        function (result) {
          if (result) {
            // Change button text back
            $timeout(function () {
              self.deleteButton = 'Delete Group';
            });

            // Call the groupFactory delete method
            groupFactory.delete(input).then(
              function (result) {
                // Fetch all the groups
                groupFactory.fetch().then(
                  function (result) {
                    // Reset the local storage group
                    $localStorage.group = result.group;
                    // Broadcast to menu to trigger menu rebuild
                    $rootScope.$broadcast('rebuildGroups');
                  },
                  function (error) {
                    console.log(error);
                  }
                );
                // Show sweet alert with callback
                sweetAlert.swal(
                  {
                    title: 'Great!',
                    text: 'Your group has been successfully deleted',
                    type: 'success',
                  },
                  function () {
                    // Remove group from groupOptions Model
                    angular.forEach(self.groupOptions, function (value, key) {
                      if (value.id === self.group[0].id) {
                        self.groupOptions.splice(key, 1);
                      }
                    });

                    // Reset the the group model
                    self.group = null;
                  },
                  function (error) {
                    console.log(error);
                  }
                );
              },
              function (error) {
                // Show sweet alert with callback
                sweetAlert.swal({
                  title: 'Oops!',
                  text: error.data.message,
                  type: 'error',
                });
              }
            );
          }
        }
      );
    };

    // Handle group delete
    self.deleteSubGroup = function (id) {
      // Change button text
      self.deleteButton = 'Deleting Group...';

      // Set the input object
      const input = {
        id: id,
      };
      // Confirm group delete
      sweetAlert.swal(
        {
          title: 'Are you sure?',
          text: 'You will not be able to recover this group!',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
        },
        function (result) {
          if (result) {
            // Change button text back
            $timeout(function () {
              self.deleteButton = 'Delete Group';
            });

            // Call the groupFactory delete method
            groupFactory.delete(input).then(
              function (result) {
                // Fetch all the groups
                groupFactory.fetch().then(
                  function (result) {
                    // Reset the local storage group
                    $localStorage.group = result.group;
                    // Broadcast to menu to trigger menu rebuild
                    $rootScope.$broadcast('rebuildGroups');
                  },
                  function (error) {
                    console.log(error);
                  }
                );
                // Show sweet alert with callback
                sweetAlert.swal(
                  {
                    title: 'Great!',
                    text: 'Your group has been successfully deleted',
                    type: 'success',
                  },
                  function () {
                    self.updateGroupInfo(self.group[0].id);
                  },
                  function (error) {
                    console.log(error);
                  }
                );
              },
              function (error) {
                // Show sweet alert with callback
                sweetAlert.swal({
                  title: 'Oops!',
                  text: error.data.message,
                  type: 'error',
                });
              }
            );
          }
        }
      );
    };

    // Add subb groups to the list
    self.addSubGroup = function () {
      // Add group to sub groups array
      self.subGroups.push(self.subGroup);

      // Reset the input
      self.subGroup = null;
    };
    // Remove from list
    self.removeSubGroup = function (i) {
      self.subGroups.splice(i, 1);
    };

    self.addSubs = function () {
      const input = {
        primary_group_id: self.group[0].id,
        sub_group_names: self.subGroups,
      };
      groupFactory.addSubs(input).then(
        function (response) {
          sweetAlert.swal({
            title: 'Great!',
            text: response.message,
            type: 'success',
          });

          $timeout(function () {
            self.subGroup = null;
            self.subGroups = [];
            self.updateGroupInfo(self.group[0].id);
          });
        },
        function (error) {
          console.log(error);
        }
      );
    };

    // Handle adding an email address
    self.addEmail = function () {
      self.emails.push({
        id: 'email-' + (self.emails.length + 1),
      });
    };
    // Handle removing an email address
    self.removeEmail = function (id) {
      self.emails.splice(1, 1);
    };

    // Handle assign create handler
    self.assignGroupHandler = function () {
      // Change button text
      self.button = 'Inviting Users To This Group...';

      // Define the email array to send to request
      self.emailArray = [];

      // Loop through emails and build email array
      angular.forEach(self.email, function (value, key) {
        self.emailArray.push(value);
      });

      // Set the input object
      const input = {
        group_id: self.selectedGroupId,
        email: self.emailArray,
      };

      // Call the groupFactory create method
      groupFactory.assign(input).then(
        function (result) {
          // Show sweet alert with callback
          sweetAlert.swal({
            title: 'Great!',
            text: 'The user/s have been successfully assigned to your group',
            type: 'success',
          });

          $timeout(function () {
            // Change button text back
            self.button = 'Invite Users To This Group';

            // Reset the form
            self.emails = null;

            // Reset the form
            self.emails = [
              {
                id: 'email-1',
              },
            ];
          });

          // Update the group model
          self.updateGroupInfo(self.group[0].id);
        },
        function (error) {
          console.log(error);
        }
      );
    };

    self.uploadListHandler = function () {
      // Upload the file
      Upload.upload({
        url: env.apiUrl + 'import',
        data: {
          file: self.file,
          name: self.selectedGroupId,
          password: self.password,
        },
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
      }).then(
        function (result) {
          if (result.data.status === 'error') {
            sweetAlert.swal({
              title: 'Oops!',
              text: result.data.message,
              type: 'error',
            });
            // Update the group model
            self.updateGroupInfo(self.group[0].id);
          } else {
            sweetAlert.swal({
              title: 'Great!',
              text: 'Users have been imported.',
              type: 'success',
            });
            // Update the group model
            self.updateGroupInfo(self.group[0].id);
          }
        },
        function (error) {
          sweetAlert.swal({
            title: 'Oops!',
            text: 'An unexpected error occured. Please contact dev@plusnarrative.com for further assistence.',
            type: 'error',
          });
        }
      );
    };

    // Assign mmultiple users to group
    self.assignCurrentUsers = function () {
      if (angular.isUndefined(self.group[0])) {
        sweetAlert.swal({
          title: 'Oops!',
          text: 'Please select a group to assign users to',
          type: 'error',
        });
      } else {
        // Change button text
        self.assignButton = 'Assigning Users...';

        // Define the email array to send to request
        self.selectedEmails = [];

        // Build user email array to send to enpoint
        angular.forEach(self.selection, function (value, key) {
          self.selectedEmails.push(value.email);
        });

        // Set the input object
        const input = {
          group_id: self.selectedGroupId,
          email: self.selectedEmails,
        };

        // Call the groupFactory create method
        groupFactory.assign(input).then(
          function (result) {
            // Show sweet alert with callback
            sweetAlert.swal({
              title: 'Great!',
              text: 'The user/s have been successfully assigned to the group',
              type: 'success',
            });
            // Change button text back
            self.assignButton = 'Assign Users';

            self.selection = null;

            // Update the group model
            self.updateGroupInfo(self.group[0].id);
          },
          function (error) {
            console.log(error);
          }
        );
      }
    };

    self.ChangeGroup = function (group) {
      angular.forEach(self.selectGroups, function (value, key) {
        if (value.id === self.selectedGroupId) {
          self.selectedGroup = value;
        }
      });
    };

    self.showSubGroup = function (id) {
      const row = $('#sub-' + id);
      const sub = $('.sub-group-users');

      sub.remove();

      if (!sub.hasClass(id)) {
        var text = '';
        angular.forEach(self.group[0].sub_groups[id].user, function (value, key) {
          text =
						text +
						'<tr class="sub-group-users ' +
						id +
						'"><td colspan="2">' +
						value.name +
						' ' +
						value.surname +
						'</td><td colspan="2">' +
						value.email +
						'</td><td> <button type="button" class="btn btn-outline-primary btn-sm" ng-click="vm.removeUserFromGroup(' +
						value.id +
						', ' +
						self.group[0].sub_groups[id].id +
						')">Remove User</button></td></tr>';
        });
        text = $(text);
        $compile(text)($scope);
        row.after(text);
      }
    };
    // Handle the user remove
    self.removeUserFromGroup = function (userId, groupId) {
      // Set the input object
      const input = {
        group_id: groupId,
        user_id: userId,
      };
      // Call the userFactory remove method
      userFactory.remove(input).then(
        function (result) {
          // Show sweet alert with callback
          sweetAlert.swal({
            title: 'Great!',
            text: 'The user was successfully removed',
            type: 'success',
          });

          $timeout(function () {
            self.updateGroupInfo(self.group[0].id);

            $('.sub-group-users').remove();
          });
        },
        function (error) {
          // Show sweet alert with callback
          sweetAlert.swal({
            title: 'Oops!',
            text: error.data.message,
            type: 'error',
          });
        }
      );
    };

    self.updateGroupInfo = function (groupId) {
      const input = {
        id: groupId,
      };
      groupFactory.get(input).then(function (result) {
        // find the index of the current group in local storage
        var foundIndex = $localStorage.group.findIndex(function (x) {
          return x.id === groupId;
        });

        // Update the local Storage group with our updated group
        $localStorage.group[foundIndex] = result.group[0];
        // Reset the groups
        self.groups = $localStorage.group;

        // Sort group names alphabetically
        self.groups = self.groups.sort(function (a, b) {
          return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
        });

        // rest the group options
        self.groupOptions = [];
        // Build group options
        angular.forEach(self.groups, function (value, key) {
          // Only add the groups the user is a facilitator of or if Admin or Super Admin
          var facilitatorArray = value.facilitator_id === null ? [] : value.facilitator_id.split(',').map(Number);
          if (facilitatorArray.includes($localStorage.user.id) || $localStorage.user.role[0].id === 1 || $localStorage.user.role[0].id === 3) {
            self.groupOptions.push(value);
          }
        });
        // Update the view
        $timeout(function () {
          // Update the group
          self.group[0] = result.group[0];
          // Get pending invoites and sort game results
          self.handleGroupSelect();
        });
      });
    };
  },
});
