angular.module('app').component('dashboardMenu', {
  templateUrl: 'app/menu/menu.html',
  controllerAs: 'vm',

  controller: function ($rootScope, $scope, $state, $localStorage, $timeout, menuFactory, groupFactory) {
    // Set this to self to avoid conflicts
    const self = this;
    // Get the user role from storage
    const role = $localStorage.role;
    // Build menu based on role
    self.menu = menuFactory.buildMenu(role);

    // Run once component has been initilaised
    self.$onInit = function () {
      if ($localStorage.group) {
        // Storing groups in local storage for quick menu build
        // Check if timestamp is older than 60 min
        if (Math.floor((new Date() - Date.parse($localStorage.timestamp)) / 60000) > 60) {
          // Reset the locastorage timestamp
          $localStorage.timestamp = new Date();
          // Fetch the group list from API
          fetchGroups();
        } else {
          // Build the menu based on group local storage
          buildGroupMenu($localStorage.group);
        }
      } else {
        // Fetch the group list
        fetchGroups();
      }

      // Fetch groups, build menu and set groups local storage
      function fetchGroups() {
        groupFactory.fetch().then(
          function (result) {
            // Set the group local storage to handle page refreshing
            $localStorage.group = result.group;
            // Build group menu list
            buildGroupMenu(result.group);
          },
          function (error) {
            console.log(error);
          }
        );
      }

      // Rebuild Groups
      $scope.$on('rebuildGroups', function (event, args) {
        // Build the menu based on group local storage
        buildGroupMenu($localStorage.group);
      });

      // Function to build the group menu items
      function buildGroupMenu(groups) {
        // Sort group names alphabetically
        groups = groups.sort(function (a, b) {
          return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
        });

        // Find the Group Results menu object
        self.menu.some(function (el) {
          // Add the groups to the sub menu array
          if (el.title === 'Group Results') {
            // Reset the submenu array
            el.submenu = [];
            // Push new object to submenu array
            angular.forEach(groups, function (value, key) {
              el.submenu.push({
                title: value.name,
                url: 'dashboard.group-results({id:' + value.id + '})', // working
              });
            });
          }
          return false;
        });

        // Redirect to group dashboard page
        $timeout(function () {
          angular.element(document).ready(function () {
            // Handle group result main menu item click
            $('.group-results').on('click', function () {
              // new SimpleBar($('#sidebar-wrapper')[0]);
              // Go to game overview dashboard
              $timeout(function () {
                $state.go('dashboard.group-dashboard');
              });
            });
          });
        });
      }
    };
  },
});
