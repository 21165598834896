angular.module('app').component('deleteGroup', {
  templateUrl: 'app/dashboard/group/group-delete.html',
  controllerAs: 'vm',
  bindings: {
    groups: '<',
  },

  controller: function ($rootScope, $localStorage, groupFactory, sweetAlert, titleService) {
    // Set this to self to avoid conflicts
    const self = this;
    // Set the basic controller data to be passed to title service
    titleService.data = {
      title: 'Delete Group',
      subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc justo augue, placerat quis nisi sit amet, feugiat tristique mi.',
    };

    // Broadcast section title to the header
    $rootScope.$broadcast('headerTitle', {
      title: 'Group Management',
      icon: 'fa-users',
    });

    // Run when component is ready
    self.$onInit = function () {
      // Set the initial button text
      self.button = 'Delete Group';
    };

    // Handle group delete handler
    self.deleteGroupHandler = function () {
      // Change button text
      self.button = 'Deleting Group...';

      // Set the input object
      const input = {
        id: self.group.id,
      };
      // Confirm group delete
      sweetAlert.swal(
        {
          title: 'Are you sure?',
          text: 'You will not be able to recover this group!',
          type: 'warning',
          showCancelButton: true,
          // confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Yes, delete it!',
        },
        function () {
          // Change button text back
          self.button = 'Delete Group';
          // Call the groupFactory delete method
          groupFactory.delete(input).then(
            function (result) {
              // Show sweet alert with callback
              sweetAlert.swal(
                {
                  title: 'Great!',
                  text: 'Your group has been successfully deleted',
                  type: 'success',
                },
                function () {
                  // Fetch all the groups
                  groupFactory.fetch().then(
                    function (result) {
                      // Reset the group
                      self.groups = result.group;
                      // Reset the local storage group
                      $localStorage.group = result.group;
                      // Broadcast rebuildGroups to trigger menu rebuild
                      $rootScope.$broadcast('rebuildGroups');
                    },
                    function (error) {
                      console.log(error);
                    }
                  );
                },
                function (error) {
                  console.log(error);
                }
              );
            },
            function (error) {
              // Show sweet alert with callback
              sweetAlert.swal({
                title: 'Oops!',
                text: error.data.message,
                type: 'error',
              });
            }
          );
        }
      );
    };
  },
});
