angular.module('app').component('removeFacilitator', {
  templateUrl: 'app/dashboard/facilitator/facilitator-remove.html',
  controllerAs: 'vm',
  bindings: {
    facilitators: '<',
  },

  controller: function ($rootScope, userFactory, facilitatorFactory, sweetAlert, titleService) {
    // Set this to self to avoid conflicts
    const self = this;
    // Set the basic controller data to be passed to title service
    titleService.data = {
      title: 'Remove Facilitator',
      subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc justo augue, placerat quis nisi sit amet, feugiat tristique mi.',
    };

    // Broadcast section title to the header
    $rootScope.$broadcast('headerTitle', {
      title: 'Facilitator Management',
      icon: 'fa-user',
    });

    // Run when component is ready
    self.$onInit = function () {
      // Set the initial button text
      self.button = 'Remove Facilitator';
    };

    // Handle facilitator remove handler
    self.removeFacilitatorHandler = function () {
      // Change button text
      self.button = 'Removing Facilitator...';

      // Set the input object
      const input = {
        id: self.facilitator.id,
      };

      sweetAlert.swal(
        {
          title: 'Are you sure?',
          text: 'You will not be able to recover this facilitator!',
          type: 'warning',
          showCancelButton: true,
          // confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Yes, remove it!',
          closeOnConfirm: false,
        },
        function () {
          // Change button text back
          self.button = 'Remove Facilitator';
          // Reset the input value
          self.facilitator = null;
          // Call the userFactory delete method
          userFactory.delete(input).then(
            function (result) {
              // Show sweet alert with callback
              sweetAlert.swal(
                {
                  title: 'Great!',
                  text: 'Your facilitator has been successfully removed',
                  type: 'success',
                },
                function () {
                  // Fetch all the facilitators
                  facilitatorFactory.fetch().then(
                    function (result) {
                      self.facilitators = result.facilitators;
                    },
                    function (error) {
                      console.log(error);
                    }
                  );
                }
              );
            },
            function (error) {
              // Show sweet alert with callback
              sweetAlert.swal({
                title: 'Oops!',
                text: error.data.message,
                type: 'error',
              });
            }
          );
        }
      );
    };
  },
});
