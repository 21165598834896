(function (window) {
  window.__env = window.__env || {};

  // API url
  window.__env.apiUrl = 'https://api.positioningwithpeople.com/v1/'; // Production
  // window.__env.apiUrl = 'http://playbox.test/'; // Development

  // Base url
  window.__env.baseUrl = '/';

  // Whether or not to enable debug mode
  // Setting this to false will disable console output
  window.__env.enableDebug = false;
})(this);
