angular.module('app').component('gameCopy', {
  templateUrl: 'app/dashboard/game/game-copy.html',
  controllerAs: 'vm',
  bindings: {
    copy: '<',
  },

  controller: function ($rootScope, $state, $stateParams, $localStorage, $scope, $timeout, gameFactory, sweetAlert, titleService, Popeye) {
    // Set this to self to avoid conflicts
    const self = this;

    // Set the basic controller data to be passed to title service
    titleService.data = {
      title: 'Game Copy',
      subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc justo augue, placerat quis nisi sit amet, feugiat tristique mi.',
    };

    // Broadcast section title to the header
    $rootScope.$broadcast('headerTitle', {
      title: 'Game Copy',
      icon: 'fa-pencil',
    });

    // Run when component is ready
    self.$onInit = function () {
      // Set the the user role
      self.role = $localStorage.role;
      // Set the environment
      self.env = env;
      // Copy array
      self.gameCopy = null;
      // Selected language
      self.language = null;

      self.selectedCopy = null;
    };

    // Get copy
    gameFactory.fetchCopy({}).then(
      function (result) {
        self.gameCopy = result.copy;
        self.englishCopy = result.copy[0];
        // Re-build the page
        $timeout(function () {});
      },
      function (error) {
        console.log(error);
      }
    );

    self.copySelectHandler = function () {
      angular.forEach(self.gameCopy, function (value, key) {
        if (value.language === self.language) {
          self.selectedCopy = value;
        }
      });
    };

    self.saveCopy = function (input) {
      gameFactory.saveCopy(input).then(
        function (result) {
          sweetAlert.swal({
            title: 'Great!',
            text: result.message,
            type: 'success',
          });
          // Re-build the page
          $timeout(function () {});
        },
        function (error) {
          // Add errors to string
          self.html = Object.keys(error.data.message)
            .map(function (k) {
              return error.data.message[k];
            })
            .join('</br>');
          sweetAlert.swal({
            title: 'Oops!',
            html: self.html,
            type: 'error',
          });
        }
      );
    };
  },
});
