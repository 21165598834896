angular.module('app').component('reset', {
  templateUrl: 'app/auth/reset.html',
  controllerAs: 'vm',

  controller: function (authFactory, sweetAlert) {
    // Set this to self to avoid conflicts
    const self = this;
    // Set initial button text
    self.button = 'Reset';
    // Handle password reset
    self.resetPasswordHandler = function () {
      // Modify button text
      self.button = 'Resetting password..';
      // Set the input object
      const input = {
        email: self.email,
      };

      // Call the authFactory
      authFactory.reset(input).then(
        function (result) {
          // Show sweet alert with callback
          sweetAlert
            .swal({
              title: 'Great!',
              text: 'Please check your email for details',
              type: 'success',
            })
            .then(function () {
              // Change rest button text back
              self.button = 'Reset';
              // Reset input fields
              self.email = null;
            });
        },
        function (error) {
          // Show sweet alert with callback
          sweetAlert
            .swal({
              title: 'Oops!',
              text: error.data.message,
              type: 'error',
            })
            .then(function () {
              // Change rest button text back
              self.button = 'Reset';
            });
        }
      );
    };
  },
});
