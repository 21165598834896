angular
  .module('app')
// Remove spaces from string
  .filter('removeSpaces', function () {
    return function (string) {
      if (!angular.isString(string)) {
        return string;
      }
      return string.replace(/[\s]/g, '');
    };
  })
// Split string of words seperated by comma into array
  .filter('commaBreak', function () {
    return function (value) {
      if (!value.length) {
        return;
      }
      return value.split(',');
    };
  });
