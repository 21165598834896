angular
  .module('app')
  .factory('dashboardFactory', function ($http, $localStorage, __env) {
    return {
      /**
			 * Fetch global analytics
			 */
      fetch: function () {
        if (__env.enableDebug) {
          console.log('dashboardFactory.fetch ran **********');
        }
        return $http({
          method: 'GET',
          url: __env.apiUrl + 'analytics',
          headers: {
            Authorization: 'Bearer ' + $localStorage.token
          }
        })
          .then(function (response) {
            return response.data;
          }).then(function (error) {
            return error;
          });
      },
      /* End fetch global analytics */

      /**
			 * Fetch game one analytics
			 */
      fetchOne: function () {
        if (__env.enableDebug) {
          console.log('dashboardFactory.fetchOne ran **********');
        }
        return $http({
          method: 'GET',
          url: __env.apiUrl + 'analytics/game1',
          headers: {
            Authorization: 'Bearer ' + $localStorage.token
          }
        })
          .then(function (response) {
            return response.data;
          }).then(function (error) {
            return error;
          });
      },
      /* End fetch game one analytics */

      /**
			 * Fetch game two analytics
			 */
      fetchTwo: function () {
        if (__env.enableDebug) {
          console.log('dashboardFactory.fetchTwo ran **********');
        }
        return $http({
          method: 'GET',
          url: __env.apiUrl + 'analytics/game2',
          headers: {
            Authorization: 'Bearer ' + $localStorage.token
          }
        })
          .then(function (response) {
            return response.data;
          }).then(function (error) {
            return error;
          });
      },
      /* End fetch game two analytics */

      /**
			 * Fetch game three analytics
			 */
      fetchThree: function () {
        if (__env.enableDebug) {
          console.log('dashboardFactory.fetchThree ran **********');
        }
        return $http({
          method: 'GET',
          url: __env.apiUrl + 'analytics/game3',
          headers: {
            Authorization: 'Bearer ' + $localStorage.token
          }
        })
          .then(function (response) {
            return response.data;
          }).then(function (error) {
            return error;
          });
      },
      /* End fetch game three analytics */
    };
  });
