angular.module('app').component('dashboard.dashboard', {
  templateUrl: 'app/dashboard/dashboard-dashboard.html',
  controllerAs: 'vm',
  bindings: {
    analytics: '<',
    analyticsOne: '<',
    analyticsTwo: '<',
    analyticsThree: '<',
  },

  controller: function ($rootScope, $localStorage, $state, titleService, $timeout) {
    // Set this to self to avoid conflicts
    const self = this;

    // Set the basic controller data to be passed to title service
    titleService.data = {
      title: 'Dashboard Home',
      subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc justo augue, placerat quis nisi sit amet, feugiat tristique mi.',
    };

    // Broadcast section title to the header
    $rootScope.$broadcast('headerTitle', {
      title: 'Dashboard Analytics',
      icon: 'fa-dashboard',
    });

    // Run when component is ready
    self.$onInit = function () {
      // Set the default primary chart data
      self.primaryChartData = [];
      // Set the primary categories
      self.primaryCategories = [];

      // Add data to primary chart data and categories
      angular.forEach(self.analytics.users_per_month, function (value, key) {
        self.primaryChartData.push(value);
        self.primaryCategories.push(key);
      });

      // Build primary chart
      buildPrimaryChart(self.primaryChartData, self.primaryCategories);

      // Add colors to analytcs object
      self.analyticsOne.view = {
        icon: 'assets/img/dashboard-contribution.png',
        title: 'Contribution Game',
        primary: 'pink', // pink
        secondary: 'primary', // primary green
      };
      // Add colors to analytcs object
      self.analyticsTwo.view = {
        icon: 'assets/img/dashboard-expertise.png',
        title: 'Expertise Game',
        primary: 'green', // green
        secondary: 'pink', // pink
      };
      // Add colors to analytcs object
      self.analyticsThree.view = {
        icon: 'assets/img/dashboard-values.png',
        title: 'Value Game',
        primary: 'orange', // orange
        secondary: 'green', // green
      };
      // Add game analytics to array
      self.gameAnalytics = [self.analyticsOne, self.analyticsTwo, self.analyticsThree];

      // Build the game data
      buildGameData();
    };

    // Build primary chart
    function buildPrimaryChart(data, categories) {
      // Set the primary chart container
      self.primaryChartContainer = $('.primary-chart-container');
      self.primaryChart = new Highcharts.Chart({
        chart: {
          renderTo: self.primaryChartContainer[0],
          type: 'areaspline',
          backgroundColor: '#f9f9f9',
        },
        title: {
          text: '',
        },
        xAxis: {
          categories: categories,
        },
        yAxis: {
          title: {
            text: 'Users',
          },
        },
        tooltip: {
          shared: true,
          valueSuffix: 'users',
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          areaspline: {
            // fillOpacity: 0.5,
            // color: 'rgba(8, 178, 166, 0.75)'
          },
        },
        series: [
          {
            color: 'rgba(8, 178, 166, 0.65)',
            data: data,
          },
        ],
      });
    }

    // Build game data
    function buildGameData() {
      // Loop through game analytics
      angular.forEach(self.gameAnalytics, function (value, key) {
        // Set the default game chart data
        let gameChartData = [];
        let gameChartCategories = [];
        let gameColors = ['rgba(239, 77, 132, 0.75)', 'rgba(153, 202, 67, 0.75)', 'rgba(245, 130, 32, 0.75)'];

        // Add data to game chart data
        angular.forEach(value.plays_per_month, function (v, k) {
          gameChartData.push(v);
          gameChartCategories.push(k);
        });

        // Build the game chart
        $timeout(function () {
          buildGameChart(key + 1, gameChartData, gameChartCategories, gameColors[key]);
        });
      });
    }

    function buildGameChart(i, data, categories, color) {
      // Set the primary chart container
      self.chartContainer = $('.game' + i + '-chart-container');
      self.primaryChart = new Highcharts.Chart({
        chart: {
          renderTo: self.chartContainer[0],
          type: 'areaspline',
          backgroundColor: '#f9f9f9',
        },
        title: {
          text: '',
        },
        xAxis: {
          categories: categories,
        },
        yAxis: {
          title: {
            text: 'Plays',
          },
        },
        tooltip: {
          shared: true,
          valueSuffix: ' plays',
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          areaspline: {
            fillOpacity: 0.5,
          },
        },
        series: [
          {
            color: color,
            data: data,
          },
        ],
      });
    }
  },
});
