angular.module('app').component('login', {
  templateUrl: 'app/auth/login.html',
  controllerAs: 'vm',
  controller: function ($state, $localStorage, $timeout, authFactory, sweetAlert, $rootScope) {
    // Set this to self to avoid conflicts
    const self = this;

    // Run when component is ready
    self.$onInit = function () {
      // Set initial button text
      self.button = 'Login';
    };

    // Handle user sign in
    self.loginHandler = function () {
      // Change button text
      self.button = 'Logging in..';
      // Set the input object
      const input = {
        username: self.email,
        password: self.password,
      };
      // Call the authFactory
      authFactory.login(input).then(
        function (result) {
          if (result.user.role_name === 'super admin' || result.user.role_name === 'facilitator') {
            // Save the user data to local storage
            // $localStorage.user = result.user;
            $localStorage.user = {
              name: result.user.name,
              id: result.user.id,
              role: result.user.role,
            };

            $localStorage.role = result.user.role_name;
            $localStorage.token = result.user.token;

            // Set the local storage timestamp
            $localStorage.timestamp = new Date();

            // Clean out the localStorage Groups
            $localStorage.group = null;

            // Go to dashboard
            $timeout(function () {
              $state.go('dashboard.dashboard');
            });
          } else {
            sweetAlert.swal(
              {
                title: 'Oops!',
                text: 'You are not permitted to access the dashboard. Please contact digital@in8motion.com if you require access. Thank you.',
                type: 'error',
              },
              function () {
                // Change login button text back
                $rootScope.$apply(function () {
                  self.button = 'Login';
                });
              }
            );
          }
        },
        function (error) {
          // Show sweet alert with callback
          sweetAlert.swal(
            {
              title: 'Oops!',
              text: error.data.message,
              type: 'error',
            },
            function () {
              $rootScope.$apply(function () {
                self.button = 'Login';
              });
            }
          );
        }
      );
    };
  },
});
